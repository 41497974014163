import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'entity-dropdown',
    template: `
        <ng-container *ngIf="options?.length > 0">
            <select
                #input
                *ngIf="itemProp"
                class="production-planning-field form-control"
                [(ngModel)]="item[itemProp]"
                (ngModelChange)="updateId($event)"
                (keydown)="keyPress($event)"
            >
                <option *ngFor="let option of options" [ngValue]="option">{{ optionDisplay(option) }}</option>
            </select>
        </ng-container>
    `,
})
export class EntityDropdownComponent<T extends IEntity, S extends IEntity> {
    @ViewChild('input') input: ElementRef;

    private _item: T;
    @Input()
    get item(): T {
        return this._item;
    }
    set item(val: T) {
        this._item = val;
        this._findOptionInstance();
    }
    @Input() itemProp: string;
    @Input() service: any;
    @Input() dropdownService: any;
    /** A function which takes an option and returns a string containing the desired display */
    @Input() optionDisplay = (option: S) => option.toString();
    @Output('keyPressed') keyPressedEmitter = new EventEmitter<KeyboardEvent>();
    @Output('optionSelected') optionSelectedEmitter = new EventEmitter<number>();
    options: S[];

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.dropdownService.getAll().subscribe((options: S[]) => {
            this.options = options;
            this._findOptionInstance();
        });
    }

    keyPress(e: KeyboardEvent): void {
        this.keyPressedEmitter.emit(e);
        if (e.key === 'Enter') {
            this.input.nativeElement.blur();
        }
    }

    updateId(selection: S): void {
        this.item[this.itemProp + 'Id'] = selection.Id;
        this.optionSelectedEmitter.emit(selection.Id);
    }

    private _findOptionInstance(): void {
        if (this.options && this.itemProp) {
            this.item[this.itemProp] = this.options.find((x) => (x as any).Id === this.item[this.itemProp + 'Id']);
            this.cdr.markForCheck();
        }
    }
}
