import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { ExtendedAuthService } from '../auth-entity/extended-auth.service';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    template: ``,
    providers: [ExtendedAuthService],
})
export class ErpAccessComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private extendedAuthService: ExtendedAuthService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        const token = this.route.snapshot.queryParamMap.get('token');
        const customerId = this.route.snapshot.queryParamMap.get('customerId');
        const authUserId = this.route.snapshot.queryParamMap.get('authId');
        if (!token || !customerId || !authUserId) {
            window.location.replace(window.location.protocol + '//' + environment.adminDomain);
        } else {
            this.extendedAuthService.erpAccessLogin(token, customerId, authUserId).subscribe((loginResp) => {
                this.authService.saveToken(loginResp, false);
                void this.router.navigateByUrl('/home');
            });
        }
    }
}
