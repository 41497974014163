import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { IOpenNote } from '@model/interfaces/open-note';
import { OpenNoteService } from './open-note.service';
import { OpenNoteDynamicControlsPartial } from '@model/partials/open-note-partial.form-controls';

@Component({
    selector: 'app-open-note',
    templateUrl: './open-note.component.html',
    styles: [
        `
            .note-creation-details {
                text-align: left;
                font-size: 0.9em;
            }
        `,
        `
            .text-field ::ng-deep textarea {
                height: 200px;
            }
        `,
    ],
})
export class OpenNoteComponent implements OnInit {
    @Input() entityId: number;
    @Input() typeId: number;
    @Input() title: string;
    @Input() canEdit: boolean;
    @Input() service: OpenNoteService;
    @Input() panel = false;
    note: IOpenNote;

    // abstract controls
    abstractOpenNoteControls: any;
    openNoteForm: FormGroup;
    formCreated = false;

    isEditing = false;
    isHovered = false;

    constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.service.get(this.entityId, this.typeId).subscribe((note) => {
            this.note = note ?? this.service.getEmptyOpenNote();
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.openNoteForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractOpenNoteControls = new OpenNoteDynamicControlsPartial(this.note, {
            formGroup: 'OpenNote',
            createdBies: null,
            lastModifiedBies: null,
            openNoteTypes: null,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            OpenNote: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.openNoteForm.valid) {
            const note = Object.assign({}, this.note, this.openNoteForm.value.OpenNote) as IOpenNote;
            this.service.createOrUpdate(this.entityId, this.typeId, note).subscribe((savedNote) => {
                this.note = savedNote;
                this.createForm();
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.openNoteForm);
            this.error();
        }
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Note saved successfully');
        this.isEditing = false;
    }
}
