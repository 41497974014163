import { HttpClient } from '@angular/common/http';
import { IEntity } from '@mt-ng2/base-service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachingBaseService } from './caching.base-service';

export class InlineEditBaseService<T extends IEntity> extends CachingBaseService<T> {
    $updateList = new Subject();

    constructor(baseUrl: string, http: HttpClient) {
        super(baseUrl, http);
    }

    refreshListCreate(object: T): Observable<number> {
        return super.create(object).pipe(tap(() => this.$updateList.next(null)));
    }
}
