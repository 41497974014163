import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-simple-label',
    template: `
        <div [ngClass]="pad ? 'display-padded' : ''">
            <b>{{ label }}:</b>
            <span>{{ value }}</span>
        </div>
    `,
})
export class SimpleLabeledFieldComponent {
    @Input() label: string;
    @Input() value: string | number;
    @Input() pad: boolean;
}
