import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InlineEditDynamicCellBase } from '../base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'non-saving-text-popup-dynamic-cell',
    styleUrls: ['./text-popup.dynamic-cell.less'],
    templateUrl: './text-popup.dynamic-cell.html',
})
export class NonSavingTextPopupDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> implements OnInit {
    @ViewChild('input') input: ElementRef;
    @Input() item: T;
    @Input() itemProp: string;
    @Input() title: string;

    modal: IModalWrapperApi;
    isNonSaving = true;
    private _cachedVal: string;

    get inputEdited(): boolean {
        return this.item[this.itemProp] !== this._cachedVal;
    }

    // Override
    onClick(event: any): boolean {
        if (super.onClick(event)) {
            this._cachedVal = this.item[this.itemProp];
            this.modal.show();
            setTimeout(() => this.input.nativeElement.focus());
            return true;
        }
        return false;
    }

    // Override -- DO NOT CALL SUPER'S SAVE
    // eslint-disable-next-line @typescript-eslint/require-await
    async save(): Promise<Observable<any>> {
        return of(null).pipe(tap(() => this.modal.close()));
    }

    // Override
    onKeyPress(e: KeyboardEvent): void {
        if (e.ctrlKey && e.key === 'Enter') {
            void this.save();
        }
        if (e.key === 'Escape') {
            this.cancel();
        }
    }

    // Override
    cancel(): void {
        super.cancel();
        this.item[this.itemProp] = this._cachedVal;
        this.modal.close();
    }
}
