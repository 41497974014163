<div class="table-responsive">
    <table class="table table-hover table-striped" [ngClass]="entityListConfig.hasTableClass ? entityListConfig.tableClass : null">
        <thead>
            <tr *ngIf="!headerTemplate && entityListConfig">
                <th></th>
                <th
                    *ngIf="selectHelper?.showSelect(printMode)"
                    [style.width.px]="selectHelper?.selectConfig?.width"
                    (click)="selectAllClicked($event)"
                >
                    <i
                        *ngIf="selectHelper?.isMultiSelect()"
                        class="fa fa-lg fa-fw"
                        aria-hidden="true"
                        [class.fa-check-square-o]="currentEntities.length && selectHelper?.isAllItemsSelected(currentEntities)"
                        [class.fa-square-o]="!currentEntities.length || !selectHelper?.isAllItemsSelected(currentEntities)"
                    ></i>
                </th>
                <ng-container *ngFor="let column of columns">
                    <th
                        *ngIf="column.showColumn(printMode)"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngStyle]="{
                            cursor: column.sort.disableSort ? 'auto' : 'pointer'
                        }"
                        [ngClass]="column.columnHeaderClass ? column.columnHeaderClass : null"
                        (click)="columnSorted(column, $event)"
                    >
                        <b
                            >{{ column.name }}
                            <span ngPreserveWhitespaces *ngIf="column.sort && !column.sort.disableSort"
                                ><i
                                    [style.opacity]="column.sort.direction === sortEnum.Asc ? null : 0.4"
                                    class="fa fa-sort-asc"
                                    aria-hidden="true"
                                ></i>
                                <i
                                    [style.opacity]="column.sort.direction === sortEnum.Desc ? null : 0.4"
                                    style="margin-left: -12px"
                                    class="fa fa-sort-desc"
                                    aria-hidden="true"
                                ></i
                            ></span>
                        </b>
                    </th>
                </ng-container>
                <th *ngIf="showDelete()" [style.width.px]="entityListConfigDelete?.width">
                    <b>{{ entityListConfigDelete?.headerText }}</b>
                </th>
            </tr>
            <tr *ngIf="headerTemplate" class="no-pointer">
                <ng-template [ngTemplateOutlet]="headerTemplate"> </ng-template>
            </tr>
        </thead>
        <tbody *ngIf="!itemTemplate && entityListConfig" cdkDropList [cdkDropListData]="entities | async" (cdkDropListDropped)="drop($event)">
            <tr
                *ngFor="let entity of entities | async"
                (click)="!entityListConfig.preventRowSelection ? itemSelected(entity, $event) : null"
                [ngClass]="getRowClass(entity)"
                cdkDrag
            >
                <div *cdkDragPreview>
                    <tr>
                        <td>
                            <span class="btn btn-default btn-flat move-handle"> <span class="fa fa-fw fa-ellipsis-v"></span> </span>
                        </td>
                        <ng-container *ngFor="let column of columns">
                            <td
                                *ngIf="column.showColumn(printMode) && !column.component && previewDisplayColumnNames.includes(column.name)"
                                class="ellipsis"
                            >
                                <div
                                    *ngIf="column.bindToInnerHtml"
                                    [innerHTML]="column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull)"
                                    class="drag-text"
                                ></div>
                                <div *ngIf="!column.bindToInnerHtml" class="drag-text">
                                    {{ column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull) }}
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </div>
                <td>
                    <span class="pull-left" cdkDragHandle>
                        <span class="btn btn-default btn-flat move-handle">
                            <span class="fa fa-fw fa-ellipsis-v"></span>
                        </span>
                    </span>
                </td>
                <td
                    *ngIf="selectHelper?.showSelect(printMode)"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="selectHelper?.selectConfig?.width"
                >
                    <i
                        *ngIf="selectHelper?.isSelectable(entity)"
                        class="fa fa-lg fa-fw"
                        aria-hidden="true"
                        [class.fa-check-square-o]="selectHelper?.isItemSelected(entity)"
                        [class.fa-square-o]="!selectHelper?.isItemSelected(entity)"
                    ></i>
                </td>
                <ng-container *ngFor="let column of columns">
                    <td
                        *ngIf="column.showColumn(printMode) && !column.component"
                        (click)="column.fireOnColumnSelected ? columnSelected(entity, column, $event) : null"
                        class="ellipsis"
                        [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                    >
                        <div
                            *ngIf="column.bindToInnerHtml"
                            [innerHTML]="column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull)"
                        ></div>
                        <a *ngIf="column.linkFunction" (click)="handleLinkFunction(entity, column, $event)" href="javascript:void(0);">{{
                            column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull)
                        }}</a>
                        <div *ngIf="!column.bindToInnerHtml && !column.linkFunction">
                            {{ column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull) }}
                        </div>
                    </td>
                    <td
                        *ngIf="column.showColumn(printMode) && column.component"
                        [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                    >
                        <ng-container
                            mtDynamicCell
                            [componentRef]="column.component"
                            [item]="entity"
                            [entityListComponentMembers]="entityListComponentMembers"
                        >
                        </ng-container>
                    </td>
                </ng-container>
                <td
                    *ngIf="showDelete() && entityListConfigDelete?.confirm && !entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                    (mtConfirm)="itemDeleted(entity, $event)"
                    [mtConfirmOptions]="entityListConfigDelete!.getConfirm(entity)"
                    [innerHtml]="entityListConfigDelete?.getColumnHtml(entity)"
                ></td>
                <td
                    *ngIf="showDelete() && !entityListConfigDelete?.confirm && !entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                    (click)="itemDeleted(entity, $event)"
                    [innerHtml]="entityListConfigDelete?.getColumnHtml(entity)"
                ></td>
                <td
                    *ngIf="showDelete() && entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                >
                    <ng-container
                        mtDynamicCell
                        [componentRef]="entityListConfigDelete!.component!"
                        [item]="entity"
                        [entityListComponentMembers]="entityListComponentMembers"
                    >
                    </ng-container>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="itemTemplate">
            <ng-container *ngFor="let entity of entities | async">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: entity }"> </ng-template>
            </ng-container>
        </tbody>
    </table>
</div>
<div *ngIf="showPagination && pagingNeeded() && !printMode">
    <mt-entity-list-pagination
        [total]="total"
        [(currentPage)]="currentPage"
        [itemsPerPage]="itemsPerPage"
        (pageChange)="getEntities()"
    ></mt-entity-list-pagination>
</div>
<div *ngIf="pagingNeeded() && printMode">
    <span class="text-center"
        ><em>limiting results to first {{ itemsPerPage | number }} of {{ total | number }}</em></span
    >
</div>
<div *ngIf="showItemCounts!; printMode">
    <span class="item-counts text-muted">{{ getItemCounts() }}</span>
</div>
<div *ngIf="(entities | async)?.length === 0">
    <h3 style="text-align: center;">{{ emptyMessage }}</h3>
</div>
