<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <h2 mtSetTitle class="tab-title">Login</h2>
    <h3 class="portal-title">Customer Portal</h3>
    <div class="portal-login-body">
        <p class="login-box-msg">{{ config.messageOverrides.loginLabel }}</p>
        <div *ngIf="!config.hideRegularSignIn">
            <form *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()">
                <div class="form-group has-feedback" [class.has-error]="isControlInvalid('username', 'required')">
                    <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div *ngIf="isControlInvalid('username', 'required')" class="small errortext" [style.position]="'absolute'">
                        Username is required
                    </div>
                </div>
                <div class="form-group has-feedback" [class.has-error]="isControlInvalid('password', 'required')">
                    <input #Password type="password" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="isControlInvalid('password', 'required')" class="small errortext" [style.position]="'absolute'">
                        Password is required
                    </div>
                </div>
                <div class="padded block-parent">
                    <div [ngSwitch]="rememberOption" class="form-check">
                        <div *ngSwitchCase="'rememberMe'">
                            <input type="checkbox" id="rememberMe" class="form-check-input" formControlName="rememberMe" />
                            <label class="form-check-label" for="rememberMe"> Remember Me</label>
                        </div>
                        <div *ngSwitchCase="'userName'">
                            <input type="checkbox" id="rememberUserName" class="form-check-input" formControlName="rememberUserName" />
                            <label class="form-check-label" for="rememberUserName"> Remember My Username</label>
                        </div>
                        <div *ngSwitchDefault></div>
                    </div>
                    <button [disabled]="!loginForm.valid" type="submit" class="btn btn-sign-in btn-flat inline-block block-right">
                        {{ config.messageOverrides.signInButtonText }}
                    </button>
                </div>
            </form>

            <ng-container *ngFor="let link of config.loginComponentLinks">
                <span style="cursor: pointer" [innerHtml]="link.linkHtml" [routerLink]="link.routerLinkPath"></span><br />
            </ng-container>
        </div>
    </div>
</div>
