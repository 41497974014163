import { INoteDynamicControlsParameters } from '@model/form-controls/note.form-controls';
import { INote } from '@model/interfaces/note';
import { IUser } from '@model/interfaces/user';
import { NoteDynamicControlsPartial } from '@model/partials/note-partial.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

export class NoteDynamicConfig<T extends INote> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private note: T, private users?: IUser[], private configControls?: string[]) {
        super();
        const additionalParamters: INoteDynamicControlsParameters = {
            users: this.users,
        };
        const dynamicControls = new NoteDynamicControlsPartial(this.note, additionalParamters);
        if (!configControls) {
            this.configControls = ['NoteText'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
