import { Component, Input, OnInit } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'simple-dropdown-dynamic-cell',
    template: `
        <!-- View only (no edit) -->
        <ng-container *ngIf="viewOnly">
            {{ displayVal }}
        </ng-container>

        <!-- Editable -->
        <ng-container *ngIf="!viewOnly">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ displayVal }}</span
                    >&nbsp;
                    <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                    <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
                </div>
                <ng-container *ngIf="isEditing">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons *ngIf="inputChanged" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <simple-dropdown [item]="clonedItem" [itemProp]="itemProp" [options]="options" [service]="service"></simple-dropdown>
        </ng-template>
    `,
})
export class SimpleDropdownInlineEditDynamicCell<T extends IEntity, S> extends InlineEditDynamicCellBase<T> implements OnInit {
    // Passed to component
    @Input() options: { value: S; display: string }[];
    @Input() service: any;

    get displayVal(): string {
        return this.options.find((o) => this.item[this.itemProp].toString() === o.value.toString()).display;
    }
}
