import { Component, Injector } from '@angular/core';
import { LoginComponent } from '@mt-ng2/login-module';

@Component({
    selector: 'admin-portal-login',
    templateUrl: './admin-portal-login.component.html',
    styles: [
        `
            .btn-sign-in {
                background-color: #21f9d5;
                border-color: #21f9d5;
                color: black;
                outline: none;
            }
            .btn-sign-in:hover {
                background-color: #05af93;
                border-color: #05af93;
                outline: none;
                color: #333;
            }
            .portal-login-body {
                padding: 20px;
                border-top: 0;
                color: #666;
            }
        `,
    ],
})
export class AdminPortalLoginComponent extends LoginComponent {
    constructor(inj: Injector) {
        super(inj);
    }
}
