import { Component, Injector, OnInit } from '@angular/core';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LoginConfig, ResetPasswordComponent } from '@mt-ng2/login-module';

@Component({
    selector: 'customer-portal-login-first-time',
    templateUrl: './customer-portal-login-first-time.component.html',
    styles: [
        `
            .btn-success {
                background-color: #89b6ef;
                border-color: #89b6ef;
                color: black;
                outline: none;
            }
            .btn-success:hover {
                background-color: #307ee2;
                border-color: #307ee2;
                outline: none;
                color: #333;
            }
        `,
    ],
})
export class CustomerPortalLoginFirstTimeComponent extends ResetPasswordComponent implements OnInit {
    contactId: number;
    authUserId: number;

    constructor(inj: Injector) {
        super(inj);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const queryParams = this.route.snapshot.queryParams;
        this.contactId = queryParams.contractId;
        this.authUserId = queryParams.authUserId;
        this.resetKey = queryParams.resetKey;
    }

    onSubmit(): void {
        const form = this.resetPasswordForm;
        if (this.authService.matchPassword(form, undefined)) {
            if (form.valid) {
                // check if authUser exists
                if (this.authUserId) {
                    this.resetPasswordForExistingAuthUser();
                }
            } else {
                markAllFormFieldsAsTouched(form);
            }
        } else {
            this.error('Passwords do not match');
        }
    }

    resetPasswordForExistingAuthUser(): void {
        const form = this.resetPasswordForm;
        this.authService
            .resetPassword(
                this.authUserId,
                form.value.Password,
                form.value.ConfirmPassword,
                this.resetKey,
            )
            .subscribe({
                next: () => {
                    this.success();
                    void this.router.navigate([this.authConfig.paths.loginPath]);
                },
                error: (error) => {
                    if (error.status === 418) {
                        this.notificationsService.error(LoginConfig.signInFailure);
                    } else if (error.status === 400) {
                        if (error.error) {
                            if (typeof error.error === 'string') {
                                this.error(error.error as string);
                            } else if (typeof error.error?.ModelState?.Service === 'string') {
                                this.error(error.error.ModelState.Service as string);
                            } else {
                                this.globalErrorHandler.handleError(error);
                            }
                        } else {
                            this.error('Something went wrong, please try again');
                        }
                    }
                },
            });
    }
}

