import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IOpenNote } from '../interfaces/open-note';
import { IUser } from '../interfaces/user';
import { IOpenNoteType } from '../interfaces/open-note-type';

export interface IOpenNoteDynamicControlsParameters {
    formGroup?: string;
    openNoteTypes?: IOpenNoteType[];
    createdBies?: IUser[];
    lastModifiedBies?: IUser[];
}

export class OpenNoteDynamicControls {

    formGroup: string;
    openNoteTypes?: IOpenNoteType[];
    createdBies?: IUser[];
    lastModifiedBies?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private opennote?: IOpenNote, additionalParameters?: IOpenNoteDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OpenNote';
        this.openNoteTypes = additionalParameters && additionalParameters.openNoteTypes || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.lastModifiedBies = additionalParameters && additionalParameters.lastModifiedBies || undefined;

        this.Form = {
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.opennote && this.opennote.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.opennote && this.opennote.DateCreated || null,
            }),
            EntityId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Entity',
                name: 'EntityId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.opennote && this.opennote.EntityId || null,
            }),
            LastModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Modified By',
                name: 'LastModifiedById',
                options: this.lastModifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.opennote && this.opennote.LastModifiedById || null,
            }),
            LastModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Modified Date',
                name: 'LastModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.opennote && this.opennote.LastModifiedDate || null,
            }),
            NoteText: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note Text',
                name: 'NoteText',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5000) ],
                validators: { 'maxlength': 5000 },
                value: this.opennote && this.opennote.hasOwnProperty('NoteText') && this.opennote.NoteText != null ? this.opennote.NoteText.toString() : '',
            }),
            OpenNoteTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Open Note Type',
                name: 'OpenNoteTypeId',
                options: this.openNoteTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.opennote && this.opennote.OpenNoteTypeId || null,
            }),
        };

        this.View = {
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.opennote && this.opennote.hasOwnProperty('CreatedById') ? this.opennote.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.opennote && this.opennote.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EntityId: new DynamicLabel({
                label: 'Entity',
                value: this.opennote && this.opennote.EntityId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            LastModifiedById: new DynamicLabel({
                label: 'Last Modified By',
                value: getMetaItemValue(this.lastModifiedBies as unknown as IMetaItem[], this.opennote && this.opennote.hasOwnProperty('LastModifiedById') ? this.opennote.LastModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastModifiedDate: new DynamicLabel({
                label: 'Last Modified Date',
                value: this.opennote && this.opennote.LastModifiedDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            NoteText: new DynamicLabel({
                label: 'Note Text',
                value: this.opennote && this.opennote.hasOwnProperty('NoteText') && this.opennote.NoteText != null ? this.opennote.NoteText.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            OpenNoteTypeId: new DynamicLabel({
                label: 'Open Note Type',
                value: getMetaItemValue(this.openNoteTypes as unknown as IMetaItem[], this.opennote && this.opennote.hasOwnProperty('OpenNoteTypeId') ? this.opennote.OpenNoteTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
