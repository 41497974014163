import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    Inject,
    ViewChild,
    Optional,
    forwardRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { DaysOfTheWeek, DefaultContextualDates, IContextualDate, IMtSearchFilterDaterangeModuleConfig, ISearchFilterDaterangeValue, MtSearchFilterDateRangeModuleConfigToken, MtSearchFilterDaterangeComponent } from '@mt-ng2/search-filter-daterange-control';
const CUSTOM = 'Custom';
const CustomDateRange: IContextualDate = {
    GetDates: () => {
        return {
            endDate: null,
            startDate: null,
        };
    },
    Name: CUSTOM,
};

export const defaultFirstDayOfWeek: DaysOfTheWeek = DaysOfTheWeek.Sunday;

export const defaultSearchFilterModuleConfig: IMtSearchFilterDaterangeModuleConfig = {
    afterText: 'after',
    beforeText: 'before',
    contextualDates: [...DefaultContextualDates, CustomDateRange],
    firstDayOfWeek: defaultFirstDayOfWeek,
    throughText: '-',
};

export interface IAppSearchFilterDaterangeValue extends ISearchFilterDaterangeValue {
    contextualDateType: IContextualDate;
}

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MtSearchFilterDaterangeComponent),
        },
    ],
    selector: 'app-search-filter-daterange',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .form-padding {
                padding: 10px;
                width: 400px;
            }
            .start-date {
                float: left;
            }
            .end-date {
                float: right;
            }
            .action-div {
                min-height: 30px;
            }
            .btn-clear {
                margin-right: 5px;
            }
            #start-after-end-error {
                position: absolute;
                bottom: 0;
                left: 10px;
            }
            .dropdown-menu {
                bottom: initial;
                margin-bottom: initial;
            }
        `,
    ],
    templateUrl: './daterange-filter.component.html',
})
export class AppSearchFilterDaterangeComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(obj: any): void {
        this.handleWriteValue(obj as ISearchFilterDaterangeValue);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected onValueChanged: ((value: any) => void) | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: (any: any) => void): void {
        this.onValueChanged = fn;
    }
    protected onTouched: (() => void) | undefined;
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState?(): void {
        // currently does not handle disabled state
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() minDate: Date | null = null;
    @Input() maxDate: Date | null = null;
    private _contextualDateType: IContextualDate | undefined;
    @Input()
    public get contextualDateType(): IContextualDate | undefined {
        return this._contextualDateType;
    }
    public set contextualDateType(value: IContextualDate | undefined) {
        if (!value) {
            this._contextualDateType = null;
        }
        if (value?.Name !== this._contextualDateType?.Name) {
            this._contextualDateType = this.contextualDateTypes?.find((x) => x.Name === value.Name);
            if (this.daterangeForm) {
                this.daterangeForm.get('ContextualDateType').setValue(this._contextualDateType);
            }
        }
    }
    @Input() availableContextualDates: IContextualDate[] | undefined;
    @Input() throughText: string | undefined;
    @Input() entity: string | undefined;
    @Input() showWeekNumbers: boolean | null = null;
    @Input() applyButtonText = 'Apply';
    @Input() clearButtonText = 'Clear';

    @Output() selectionChanged: EventEmitter<IAppSearchFilterDaterangeValue> = new EventEmitter<IAppSearchFilterDaterangeValue>();
    @ViewChild('ngbDropdown', { static: false }) ngbDropdown: NgbDropdown | undefined;

    firstDayOfTheWeek: DaysOfTheWeek = defaultFirstDayOfWeek;
    daterangeForm!: UntypedFormGroup;
    startDateField!: DynamicField;
    endDateField!: DynamicField;
    contextualDateTypes: IContextualDate[] | undefined;
    subscriptions: Subscription = new Subscription();

    get contextualDateIsCustom(): boolean {
        return this.contextualDateType?.Name === CUSTOM;
    }

    constructor(
        @Inject(MtSearchFilterDateRangeModuleConfigToken)
        @Optional()
        private searchFilterModuleConfig: IMtSearchFilterDaterangeModuleConfig,
    ) {}

    ngOnInit(): void {
        this.searchFilterModuleConfig = Object.assign(defaultSearchFilterModuleConfig, this.searchFilterModuleConfig);
        this.firstDayOfTheWeek = this.searchFilterModuleConfig.firstDayOfWeek ?? defaultFirstDayOfWeek;
        this.daterangeForm = new UntypedFormGroup({
            ContextualDateType: new UntypedFormControl(this.contextualDateType),
        });
        this.daterangeForm.setValidators(this.validateDateIsNotReversed.bind(this) as ValidatorFn);
        this.setDynamicFields();
        this.subscribeToContextualDateTypeChange();
        this.SetContextualDateRanges();
    }

    private SetContextualDateRanges(): void {
        if (this.availableContextualDates && this.availableContextualDates.length > 0) {
            this.contextualDateTypes = this.availableContextualDates;
        } else {
            this.contextualDateTypes = this.searchFilterModuleConfig.contextualDates;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.daterangeForm && (changes['startDate'] || changes['endDate'])) {
            let changeWasApplied = false;
            if (changes['startDate'] && this.daterangeForm.value.start !== changes['startDate'].currentValue) {
                this.daterangeForm.get('start')?.setValue(changes['startDate'].currentValue);
                changeWasApplied = true;
            }
            if (changes['endDate'] && this.daterangeForm.value.end !== changes['endDate'].currentValue) {
                this.daterangeForm.get('end')?.setValue(changes['endDate'].currentValue);
                changeWasApplied = true;
            }
            if (changeWasApplied) {
                this.emitChange();
            }
        }
    }

    setDynamicFields(): void {
        if (this.contextualDateType && !this.contextualDateIsCustom) {
            const dateRange = this.contextualDateType.GetDates(this.firstDayOfTheWeek);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }
        this.startDateField = this.getDynamicFieldDate('start', this.startDate);
        this.endDateField = this.getDynamicFieldDate('end', this.endDate);
    }

    getDynamicFieldDate(name: string, value: Date | null): DynamicField {
        const minDateAsNgbDateStruct = this.minDate
            ? ({
                  day: this.minDate.getDate(),
                  month: this.minDate.getMonth() + 1,
                  year: this.minDate.getFullYear(),
              } as NgbDateStruct)
            : undefined;
        const maxDateAsNgbDateStruct = this.maxDate
            ? ({
                  day: this.maxDate.getDate(),
                  month: this.maxDate.getMonth() + 1,
                  year: this.maxDate.getFullYear(),
              } as NgbDateStruct)
            : undefined;
        const field = new DynamicField({
            formGroup: '',
            label: name,
            name: name,
            type: new DynamicFieldType({
                datepickerOptions: {
                    firstDayOfTheWeek: this.firstDayOfTheWeek,
                    maxDate: maxDateAsNgbDateStruct,
                    minDate: minDateAsNgbDateStruct,
                    showWeekNumbers: this.showWeekNumbers || this.searchFilterModuleConfig.showWeekNumbers,
                    showClearButton: false,
                },
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            }),
            value: value,
        });
        return field;
    }

    validateDateIsNotReversed(): ValidationErrors | null {
        const form = this.daterangeForm;
        if (!form) {
            return null;
        }
        const startDateControl = form.get('start');
        const endDateControl = form.get('end');
        if (!startDateControl || !endDateControl) {
            return null;
        }
        const startDate = startDateControl.value;
        const endDate = endDateControl.value;
        if (startDate && endDate && startDate > endDate) {
            return {
                dateIsReversed: true,
            };
        }
        return null;
    }

    hasDatesReversedError(): boolean {
        return this.daterangeForm?.errors?.['dateIsReversed'] ?? false;
    }

    selectedItemsText(): string {
        if (!this.startDate) {
            if (!this.endDate) {
                return `Any ${this.entity}`;
            }
            return `${this.entity}: <small>${this.searchFilterModuleConfig.beforeText} ${this.getDateString(this.endDate)}</small>`;
        }
        if (!this.endDate) {
            return `${this.entity}: <small>${this.searchFilterModuleConfig.afterText} ${this.getDateString(this.startDate)}</small>`;
        }
        return `${this.entity}: <small>${this.getDateString(this.startDate)} ${
            this.throughText || this.searchFilterModuleConfig.throughText
        } ${this.getDateString(this.endDate)}</small>`;
    }

    getDateString(date: Date): string {
        // Fix for typescript compiler error https://stackoverflow.com/a/66590756/5997923
        const options = { year: 'numeric', month: 'short', day: 'numeric' } as const;
        return date.toLocaleDateString('en-US', options);
    }

    applyChanges(): void {
        if (this.daterangeForm?.valid) {
            this.startDate = this.daterangeForm.value.start;
            this.endDate = this.daterangeForm.value.end;
        }
        this.emitChange();
        this.closeDropdown();
    }

    closeDropdown(): void {
        this.ngbDropdown?.close?.();
    }

    emitChange(): void {
        const value: IAppSearchFilterDaterangeValue = {
            endDate: this.endDate,
            startDate: this.startDate,
            contextualDateType: this.contextualDateType,
        };
        this.selectionChanged.emit(value);
        this.onValueChanged?.(value);
        // this onTouched could/should be called much earlier, but really this
        // control will not often be used in a context where when touched will
        // matter, so not going to add more to this component to move it to an
        // earlier spot (i.e. when the dropdown opens, etc.)
        this.onTouched?.();
    }

    clearValues(): void {
        this.daterangeForm?.reset();
        this.contextualDateType = null;
        this.applyChanges();
    }

    subscribeToContextualDateTypeChange(): void {
        const control = this.daterangeForm?.get('ContextualDateType');
        if (control) {
            this.subscriptions.add(
                control.valueChanges.subscribe((value: IContextualDate) => {
                    if (!value) {
                        return;
                    }
                    this.contextualDateType = value;
                    const dateRange: ISearchFilterDaterangeValue = value.GetDates(this.firstDayOfTheWeek);
                    if (!this.contextualDateIsCustom) {
                        this.assignDates(dateRange);
                    }
                }),
            );
        }
    }

    protected handleWriteValue(value: ISearchFilterDaterangeValue): void {
        this.startDate = value?.startDate ?? null;
        this.endDate = value?.endDate ?? null;
        if (this.daterangeForm?.get('start')) {
            this.daterangeForm.get('start')?.setValue(this.startDate);
            this.daterangeForm.get('end')?.setValue(this.endDate);
        } else {
            if (this.startDateField) {
                this.startDateField.value = this.startDate;
            }
            if (this.endDateField) {
                this.endDateField.value = this.endDate;
            }
        }
    }

    assignDates(dateRange: ISearchFilterDaterangeValue): void {
        this.daterangeForm?.get('start')?.setValue(dateRange.startDate);
        this.daterangeForm?.get('end')?.setValue(dateRange.endDate);
        this.applyChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
