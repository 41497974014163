/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'number-dynamic-cell',
    template: `
        <!-- View only (no edit) -->
        <ng-container *ngIf="viewOnly && canView(item[itemProp])">
            {{ displayData(item[itemProp]?.toLocaleString()) }}
        </ng-container>

        <!-- Editable -->
        <ng-container *ngIf="!viewOnly && canView(item[itemProp])">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ displayData(item[itemProp]?.toLocaleString()) }}</span
                    >&nbsp;
                    <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                    <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
                </div>
                <ng-container *ngIf="isEditing">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons
                        *ngIf="!saveOnBlur"
                        [showSaveBtn]="showSaveBtn"
                        (save)="subscribeSave()"
                        (cancel)="cancel()"
                    ></save-cancel-buttons>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons
                    *ngIf="inputChanged && !saveOnBlur"
                    [showSaveBtn]="showSaveBtn"
                    (save)="subscribeSave()"
                    (cancel)="cancel()"
                ></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <input
                #input
                class="form-control min-three-digits"
                type="number"
                [min]="min"
                [max]="max"
                [placeholder]="placeholder"
                [(ngModel)]="clonedItem[itemProp]"
                (keydown)="onKeyPress($event)"
                (blur)="handleOnChange()"
            />
        </ng-template>
    `,
})
export class NumberInlineEditDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> {
    @ViewChild('input') input: ElementRef;
    @Input() displayData = (data: string) => data;
    @Input() min = 1;
    @Input() max: number;
    @Input() placeholder = '';
    @Input() allowZeroValue = false;
    @Input() showSaveBtn = true;
    @Output() inputValueChanged = new EventEmitter<T>();

    // Override
    onClick(event: any): boolean {
        setTimeout(() => this.input?.nativeElement.focus());
        return super.onClick(event);
    }

    onValidationFail(): void {
        setTimeout(() => this.input.nativeElement.focus());
    }

    canView(value: any): boolean {
        return value || this.allowZeroValue;
    }

    cancel(): void {
        super.cancel();
        // Reset the entity dropdown
        this.clonedItem = JSON.parse(JSON.stringify(this.item));
        this.sendUpdatedValue(this.clonedItem);
    }

    handleOnChange(): void {
        if (this.saveOnBlur) {
            this.subscribeSave();
            return;
        }
        this.sendUpdatedValue(this.clonedItem);
    }

    sendUpdatedValue(item: T): void {
        this.inputValueChanged.emit(item);
    }
}
