import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'checkbox-dynamic-cell',
    template: `
        <ng-container *ngIf="viewOnly">
            {{ item[itemProp] ? 'Yes' : 'No' }}
        </ng-container>

        <ng-container *ngIf="!viewOnly">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ item[itemProp] ? 'Yes' : 'No' }}</span>&nbsp;
                    <a class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                </div>
                <div *ngIf="isEditing" (keydown)="onKeyPress($event)">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons *ngIf="!saveOnBlur" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
                </div>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons *ngIf="inputChanged && !saveOnBlur" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <simple-checkbox
                [(checked)]="clonedItem[itemProp]"
                (checkedChange)="onCheckedChange()"
                [checkboxTitle]="checkboxTitle"
                (keyPress)="onKeyPress($event)"
            ></simple-checkbox>
        </ng-template>
    `,
})
export class CheckboxInlineEditDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> {
    @Input() checkboxTitle = 'Archive';

    onCheckedChange(): void {
        this.clonedItem[this.itemProp];
        if (this.saveOnBlur) {
            this.subscribeSave();
        }
    }
}
