import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'simple-dropdown',
    template: `
        <ng-container *ngIf="options?.length > 0">
            <select #input
                class="production-planning-field form-control"
                [(ngModel)]="item[itemProp]"
                (keydown)="keyPress($event)"
            >
                <option *ngFor="let option of options" [value]="option.value">{{ option.display }}</option>
            </select>
        </ng-container>
    `,
})
export class SimpleDropdownComponent<T extends IEntity, S> {
    @ViewChild('input') input: ElementRef;
    @Input() item: T;
    @Input() itemProp: string;
    @Input() options: { value: S, display: string }[];
    @Input() service: any;
    @Output('keyPress') keyPressedEmitter = new EventEmitter<KeyboardEvent>();

    keyPress(e: KeyboardEvent): void {
        this.keyPressedEmitter.emit(e);
        if (e.key === 'Enter') {
            this.input.nativeElement.blur();
        }
    }
}
