<div
    [ngClass]="panel ? 'miles-card padded' : ''"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
    *ngIf="!isEditing"
>
    <h4>
        {{ title }}
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>

    <div *ngIf="note && note.NoteText">
        <div style="text-align: left">
            <span style="white-space: pre-line"> {{ note.NoteText }}</span>
        </div>
        <br />
        <div *ngIf="!note.LastModifiedBy">
            <div class="note-creation-details">
                <span><b>Entered By: </b>{{ note.CreatedBy | fullName }}</span>
            </div>
            <div class="note-creation-details">
                <span><b>Entered Date: </b>{{ note.DateCreated | date : 'medium' }}</span>
            </div>
        </div>
        <div *ngIf="note.LastModifiedBy">
            <div class="note-creation-details">
                <span><b>Last Modified By: </b>{{ note.LastModifiedBy | fullName }}</span>
            </div>
            <div class="note-creation-details">
                <span><b>Last Modified Date: </b>{{ note.LastModifiedDate | date : 'medium' }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="!note || !note.NoteText">
        <em>No notes recorded</em>
        <br />
    </div>
</div>

<div *ngIf="isEditing" [ngClass]="panel ? 'miles-card padded' : ''">
    <h4>
        {{ title }}
    </h4>
    <div *ngIf="formCreated">
        <form class="padded row" [formGroup]="openNoteForm" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-md-12">
                    <mt-dynamic-field class="text-field" [field]="abstractOpenNoteControls.NoteText" [form]="openNoteForm"></mt-dynamic-field>
                </div>
            </div>
            <div class="row col-md-12">
                <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">Save</button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </form>
    </div>
</div>
