import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'text-popup-create-dynamic-cell',
    template: `
        <text-popup-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [title]="title"
            [service]="service"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
        ></text-popup-dynamic-cell>

        <non-saving-text-popup-dynamic-cell *ngIf="!item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [title]="'new ' + title"
        ></non-saving-text-popup-dynamic-cell>
    `,
})
export class TextPopupInlineCreateDynamicCell<T extends IEntity> extends InlineCreateDynamicCellBase<T> {
    @Input() title: string;
}
