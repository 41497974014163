import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-icon-button',
    template: `
        <button
            class="btn btn-primary"
            (click)="onClick.emit($event)"
            (mouseenter)="showTooltip()"
            (mouseleave)="hideTooltip()"
            [disabled]="disabled"
        >
            <i [class]="iconString"></i>
        </button>

        <span *ngIf="tooltipShowing" class="tooltip-wrapper">
            <span class="icon-tooltip">
                {{ tooltipText }}
            </span>
        </span>
    `,
    styles: [
        `
            .tooltip-wrapper {
                position: relative;
            }
        `,

        `
            .icon-tooltip {
                position: absolute;
                bottom: 15px;
                right: 30px;
                border: 1px grey solid;
                padding: 5px;
                border-radius: 5px;
                background-color: white;
                white-space: nowrap;
                z-index: 999;
            }
        `,
        `
            .btn-primary:focus {
                background-color: #05af93;
                border-color: #05af93;
                outline: none;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
            }
        `,
    ],
})
export class AppIconButtonComponent {
    @Input() iconString: string;
    @Input() tooltipText: string;
    @Input() set disabled(value: boolean) {
        if (value != this._disabled) {
            this._disabled = value;
        }
    }
    get disabled(): boolean {
        return this._disabled;
    }
    _disabled: boolean;
    @Output() disabledChange = new EventEmitter<boolean>();
    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>(null);

    tooltipShowing = false;

    constructor(private cdr: ChangeDetectorRef) {}

    onDoubleClickIsDisabledChange(value: boolean): void {
        this.disabled = value;
        this.disabledChange.emit(value);
    }

    showTooltip(): void {
        this.tooltipShowing = true;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    hideTooltip(): void {
        setTimeout(() => {
            this.tooltipShowing = false;
            this.cdr.markForCheck();
        }, 200);
    }
}
