import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InlineEditDynamicCellBase } from '../base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'text-popup-dynamic-cell',
    styleUrls: ['./text-popup.dynamic-cell.less'],
    templateUrl: './text-popup.dynamic-cell.html',
})
export class TextPopupDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> implements OnInit {
    @ViewChild('input') input: ElementRef;
    @Input() item: T;
    @Input() itemProp: string;
    @Input() title: string;
    @Input() service: any;

    modal: IModalWrapperApi;
    isNonSaving = false;

    get inputEdited(): boolean {
        return this.item[this.itemProp] !== this.clonedItem[this.itemProp];
    }

    // Override
    onClick(event: any): boolean {
        if (super.onClick(event)) {
            this.modal.show();
            setTimeout(() => this.input.nativeElement.focus());
            return true;
        }
        return false;
    }

    // Override
    async save(): Promise<Observable<any>> {
        return super.save().then((obs) => obs.pipe(tap(() => this.modal.close())));
    }

    // Override
    onKeyPress(e: KeyboardEvent): void {
        if (e.ctrlKey && e.key === 'Enter') {
            void this.save();
        }
        if (e.key === 'Escape') {
            this.cancel();
        }
    }

    cancel(): void {
        super.cancel();
        this.modal.close();
    }
}
