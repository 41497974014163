import { BaseService, ICreateOptions, IEntity } from '@mt-ng2/base-service';
import { IName, INamedEntity } from '@mt-ng2/multiselect-control';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';

export class CachingBaseService<T extends IEntity> extends BaseService<T> {
    private _cachedItems = new BehaviorSubject<T[]>(null);
    private _callPending: boolean;

    getAll(): Observable<T[]> {
        if (this._callPending || this._cachedItems.getValue()) {
            return this._cachedItems.asObservable().pipe(
                filter((x) => x != null),
                take(1),
            );
        } else {
            this._callPending = true;
            return super.getAll().pipe(
                tap((items) => {
                    this._cachedItems.next(items);
                    this._clearCacheAfterXSeconds(30);
                }),
                finalize(() => (this._callPending = false)),
            );
        }
    }

    getAllAsMtSearchFilterItem(): Observable<MtSearchFilterItem[]> {
        return (this.getAll() as unknown as Observable<INamedEntity[]>).pipe(map((i) => i.map((itm) => new MtSearchFilterItem(itm, false))));
    }

    private _clearCacheAfterXSeconds(x: number): void {
        setTimeout(() => this._cachedItems.next(null), x * 1000);
    }

    // Below functions are simply overrides that clear out the local cache

    create(object: T, options?: ICreateOptions): Observable<number> {
        this._cachedItems.next(null);
        return super.create(object, options);
    }

    createWithFks(object: T): Observable<number> {
        this._cachedItems.next(null);
        return super.createWithFks(object);
    }

    update(object: T): Observable<any> {
        this._cachedItems.next(null);
        return super.update(object);
    }

    updateWithFks(object: T): Observable<any> {
        this._cachedItems.next(null);
        return super.updateWithFks(object);
    }

    updateList(object: T[]): Observable<any> {
        this._cachedItems.next(null);
        return super.updateList(object);
    }

    updatePartial(object: object, id: number): Observable<any> {
        this._cachedItems.next(null);
        return super.updatePartial(object, id);
    }

    updatePartialVersionable(object: any, id: number): Observable<any> {
        this._cachedItems.next(null);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return super.updatePartialVersionable(object, id);
    }

    updateVersion(object: T): Observable<number[]> {
        this._cachedItems.next(null);
        return super.updateVersion(object);
    }

    updateVersionWithFks(object: T): Observable<number[]> {
        this._cachedItems.next(null);
        return super.updateVersionWithFks(object);
    }

    delete(id: number): Observable<any> {
        this._cachedItems.next(null);
        return super.delete(id);
    }

    deleteVersion(object: T): Observable<any> {
        this._cachedItems.next(null);
        return super.deleteVersion(object);
    }
}
