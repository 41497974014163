export class RGB {
    red: number;
    green: number;
    blue: number;
}

export function HexToRGB(hexCode: string): RGB {
    const r = hexCode.substring(0, 2);
    const g = hexCode.substring(2, 4);
    const b = hexCode.substring(4);
    return {
        red: parseInt(r, 16),
        green: parseInt(g, 16),
        blue: parseInt(b, 16),
    } as RGB;
}

export function RGBToHex(rgb: RGB): string {
    const r = rgb.red || 0;
    const g = rgb.green || 0;
    const b = rgb.blue || 0;
    return (
        r.toString(16).toUpperCase().padStart(2, '0') +
        g.toString(16).toUpperCase().padStart(2, '0') +
        b.toString(16).toUpperCase().padStart(2, '0')
    );
}

export function validRGB(red: number, blue: number, green: number): boolean {
    if (
        (red && (red > 255 || red < 0 || red % 1 !== 0)) ||
        (green && (green > 255 || green < 0 || green % 1 !== 0)) ||
        (blue && (blue > 255 || blue < 0 || blue % 1 !== 0))
    ) {
        return false;
    }
    return true;
}
