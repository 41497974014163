import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SavedSearchService } from './services/saved-search.service';
import { SavedSearchPages } from '@model/enums/saved-search-pages.enum';
import { ISavedSearch } from '@model/interfaces/saved-search';
import { SearchParams } from '@mt-ng2/common-classes';
import { IModalOptions, ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-saved-search',
    templateUrl: 'saved-search.component.html',
    styles: [
        `
            .saved-search-bar {
                background-color: #ccc;
                padding: 5px;
                min-height: 45px;
            }
        `,
        `
            .input-inline {
                display: inline-block;
                width: 250px;
            }
        `,
    ],
})
export class SavedSearchComponent implements OnInit {
    @Input() searchPageId: SavedSearchPages;
    @Input() set currentSearchParams(value: SearchParams) {
        this._currentSearchParams = value;
    }
    get currentSearchParams(): SearchParams {
        return this._currentSearchParams;
    }
    get stringifiedSearchParams(): string {
        return JSON.stringify(this.currentSearchParams);
    }

    disableDeleteButton = false;
    private _currentSearchParams: SearchParams;

    @Output() onSearchSelected = new EventEmitter<{ search: SearchParams; inital: boolean }>();

    searches: ISavedSearch[] = [];
    private _selectedSearch: ISavedSearch;

    get selectedSearch(): ISavedSearch {
        return this._selectedSearch;
    }

    set selectedSearch(value: ISavedSearch) {
        this._selectedSearch = JSON.parse(JSON.stringify(value));
    }
    //modal
    overwriteModalOptions: IModalOptions = {
        icon: 'warning',
        text: 'Search parameters have changed but the name has not. Do you want to overwrite the current saved search?',
        title: 'Overwrite current saved search?',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonText: 'Save',
    };
    deleteModalOptions: IModalOptions = {
        icon: 'warning',
        title: 'Delete saved search?',
        showCancelButton: true,
        allowOutsideClick: true,
    };

    constructor(
        private savedSearchService: SavedSearchService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.savedSearchService.getSavedSearches(this.searchPageId).subscribe((savedSearches) => {
            this.searches = savedSearches;
            this.selectedSearch = this.searches.find((s) => s.IsDefault) || this.savedSearchService.getNewSavedSearch(this.searchPageId);
            this.searchSelected(true);
        });
    }

    searchSelected(inital = false): void {
        if (this.selectedSearch) {
            const search = this.selectedSearch.Search;
            if (search) {
                const searchParams = JSON.parse(search) as SearchParams;
                this.onSearchSelected.emit({ search: searchParams, inital: inital });
            }
        } else {
            this.selectedSearch = this.savedSearchService.getNewSavedSearch(this.searchPageId);
        }
    }

    deleteSearch(): void {
        this.modalService.showModal(this.deleteModalOptions).subscribe((response) => {
            if (response.value) {
                this.savedSearchService.deleteSearch(this.searchPageId, this.selectedSearch.Id).subscribe(() => {
                    this.searches = this.searches.filter((s) => s.Id !== this.selectedSearch.Id);
                    this.selectedSearch = this.savedSearchService.getNewSavedSearch(this.searchPageId);
                    this.notificationsService.success('Search deleted');
                });
            } else {
                this.disableDeleteButton = false;
                this.cdr.detectChanges();
            }
        });
    }

    saveSearch(): void {
        if (!this.selectedSearch.Name) {
            return this.notificationsService.error('Please enter a name for the search');
        } else {
            const currentSearch = this.stringifiedSearchParams;
            const selectedExists = this.searches.find((s) => s.Id === this.selectedSearch.Id);
            const searchUpdated = currentSearch !== this.selectedSearch.Search;
            const nameUpdated = this.selectedSearch.Name !== selectedExists?.Name;
            if (selectedExists && searchUpdated && !nameUpdated) {
                this.modalService.showModal(this.overwriteModalOptions).subscribe((response) => {
                    if (response.value) {
                        this.save();
                    }
                });
            } else {
                // if updated and name has changed it's a new search
                if (searchUpdated) {
                    this.selectedSearch.Id = 0;
                }
                this.save();
            }
        }
    }

    private save(): void {
        this.selectedSearch.Search = this.stringifiedSearchParams;
        this.savedSearchService.saveSearch(this.searchPageId, this.selectedSearch).subscribe((searches) => {
            this.selectedSearch = searches.find((s) => s.Name === this.selectedSearch.Name);
            this.searches = searches;
            this.notificationsService.success('Search saved');
        });
    }
}
