<button class="btn btn-primary" (click)="openPopup($event)"><i class="fa fa-fw fa-user"></i></button>

<mt-modal-wrapper
    [autoShow]="false"
    [allowOutsideClick]="true"
    [showActions]="false"
    [options]="{ showCloseButton: true }"
    (ready)="modal = $event"
>
    <h2 class="popup-title">{{ title }} History</h2>
    <br />
    <div class="history-details">
        <ng-container
            *ngTemplateOutlet="historyTemplate; context: {
            text: 'Created',
            $implicit: createdBy,
            date: dateCreated
        }"
        ></ng-container>
        <br />
        <br />
        <ng-container *ngIf="modifiedBy">
            <ng-container
                *ngTemplateOutlet="historyTemplate; context: {
                text: 'Last modified',
                $implicit: modifiedBy,
                date: dateModified
            }"
            ></ng-container>
        </ng-container>
        <ng-container *ngIf="!modifiedBy" )>
            <i>This {{ title.toLowerCase() }} has not been modified</i>
        </ng-container>
    </div>
    <br />
    <button class="btn btn-primary" (click)="modal.close()">Close</button>
</mt-modal-wrapper>

<ng-template #historyTemplate let-user let-date="date" let-text="text">
    {{ text }} by <b><i>{{ user | fullName }}</i></b>
    <br />
    at <b><i>{{ datePipe.transform(date, 'h:mm a') }}</i></b>
    <br />
    on <b><i>{{ datePipe.transform(date, 'MMMM d, y') }}</i></b>
</ng-template>
