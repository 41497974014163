import { Injectable, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
/*******************
 * based on this article: https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page/
 * We create this service to use the location back without the bug that will break the app
 * if we go back without a page to go back to
 *
 * This allows us to go back to the last page we were at rather than specifying a specific
 * path to navigate to
 */

@Injectable({ providedIn: 'root'})
export class NavigationService {
    private navHistory: string[] = [];
    constructor(
        private router: Router,
        private location: Location
    ) {
        this.router.events.subscribe((event) => {
            if(event instanceof NavigationEnd) {
                this.navHistory.push(event.urlAfterRedirects);
            }
        });
    }

    /**
     * allows for dynamic back without the potential to break
     * @param path optional parameter to override where to go if there is no history to reference
     */
    back(path = '/'): void {
        this.navHistory.pop();
        if (this.navHistory.length > 0) {
            this.location.back();
        } else {
            void this.router.navigate([path]);
        }
    }


}
