import { NgModule } from '@angular/core';

import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';

/**
 * Feature Module contains all imports and exports that are specific to Features.  This makes
 * importing the standard grouping of mt-ng2 and angular modules easy and also helps with
 * bundling by having a consistent feature module grouping.
 *
 * Would only be imported into Features such as "CustomerModule" and "UserModule" and would
 * not be imported into root modules such as "AppModule" or "AdminPortalModule", nor other
 * base modules such as "AdminPortalSharedModule".
 *
 * Tip: If the import/export should be included in all modules, including the root AppModule at
 * startup, than consider putting it into "AdminPortalSharedModule" instead.
 */
@NgModule({
    exports: [
        MtSearchBarControlModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicFormModule,
        EntityComponentsNotesModule,
        EntityComponentsPhonesModule,
        EntityComponentsAddressesModule,
        EntityComponentsDocumentsModule,
        MtManagedListControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        MtSearchFilterDaterangeModule,
    ],
    imports: [
        MtSearchBarControlModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicFormModule,
        EntityComponentsNotesModule,
        EntityComponentsPhonesModule,
        EntityComponentsAddressesModule,
        EntityComponentsDocumentsModule,
        MtManagedListControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        MtSearchFilterDaterangeModule,
    ],
})
export class AdminPortalFeatureModule {}
