import { NgModule } from '@angular/core';
import { MtLoginModule } from '@mt-ng2/login-module';
import { AdminPortalLoginComponent } from './admin-portal-login.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

@NgModule({
    declarations: [AdminPortalLoginComponent],
    exports: [AdminPortalLoginComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MtDisableDuringHttpCallsModule, MtLoginModule],
})
export class AdminPortalLoginModule {}
