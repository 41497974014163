import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { INote } from '../interfaces/note';
import { INoteType } from '../interfaces/note-type';
import { IUser } from '../interfaces/user';

export interface INoteDynamicControlsParameters {
    formGroup?: string;
    noteTypes?: INoteType[];
    users?: IUser[];
}

export class NoteDynamicControls {

    formGroup: string;
    noteTypes?: INoteType[];
    users?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private note?: INote, additionalParameters?: INoteDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Note';
        this.noteTypes = additionalParameters && additionalParameters.noteTypes || undefined;
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.note && this.note.DateCreated || null,
            }),
            NoteText: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note Text',
                name: 'NoteText',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5000) ],
                validators: { 'maxlength': 5000 },
                value: this.note && this.note.hasOwnProperty('NoteText') && this.note.NoteText != null ? this.note.NoteText.toString() : '',
            }),
            NoteTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note Type',
                name: 'NoteTypeId',
                options: this.noteTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.note && this.note.NoteTypeId || null,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.note && this.note.UserId || null,
            }),
        };

        this.View = {
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.note && this.note.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            NoteText: new DynamicLabel({
                label: 'Note Text',
                value: this.note && this.note.hasOwnProperty('NoteText') && this.note.NoteText != null ? this.note.NoteText.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoteTypeId: new DynamicLabel({
                label: 'Note Type',
                value: getMetaItemValue(this.noteTypes as unknown as IMetaItem[], this.note && this.note.hasOwnProperty('NoteTypeId') ? this.note.NoteTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.note && this.note.hasOwnProperty('UserId') ? this.note.UserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
