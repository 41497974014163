import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService, MetaItem } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IMetaItem, IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        ReceiveInventoryNotifications: false,
        SalesRepresentative: false,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http, null, { entityName: 'User' });
    }

    formatTitleText(entity: IUser): void {
        this.setTitle(entity && entity.Id ? `User: ${entity.FirstName} ${entity.LastName}` : 'Add User');
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    getSalesRepresentatives(): Observable<IUser[]> {
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({
                name: 'SalesRep',
                value: 'true',
            }),
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'FirstName',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 999,
        };
        return super.get(new SearchParams(searchEntity)).pipe(map((users) => users.body));
    }

    getSalesRepresentativesAsFilterItem(): Observable<MtSearchFilterItem[]> {
        return this.getSalesRepresentatives().pipe(
            map((salesReps) => salesReps.map((sr) => new MtSearchFilterItem({ Id: sr.Id, Name: `${sr.FirstName} ${sr.LastName}` }, false))),
        );
    }

    getSalesRepresentativesAsMetaItem(): Observable<IMetaItem[]> {
        return this.getSalesRepresentatives().pipe(map((salesReps) => salesReps.map((sr) => new MetaItem(sr.Id, `${sr.FirstName} ${sr.LastName}`))));
    }
}
