<span ngbDropdown autoClose="outside" class="dropdown" #ngbDropdown="ngbDropdown">
    <button ngbDropdownToggle class="btn btn-transparent dropdown-toggle" type="button">
        <span [innerHtml]="selectedItemsText()"></span>
        <span class="caret"></span>
    </button>
    <div ngbDropdownMenu class="dropdown-menu">
        <form [formGroup]="daterangeForm" class="form-padding">
            <div class="action-div row">
                <div class="col-md-6">
                    <label for="ContextualDateType">Date Range</label>
                    <select formControlName="ContextualDateType" name="ContextualDateType">
                        <option *ngFor="let type of contextualDateTypes" [ngValue]="type">{{ type.Name }}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <button (click)="applyChanges()" class="btn btn-primary btn-sm pull-right" type="button">{{ applyButtonText }}</button>
                    <button (click)="clearValues()" class="btn btn-default btn-sm pull-right btn-clear" type="button">{{ clearButtonText }}</button>
                </div>
            </div>
            <div [formGroup]="daterangeForm" [hidden]="!contextualDateIsCustom">
                <mt-dynamic-field [field]="startDateField" [form]="daterangeForm" class="start-date"></mt-dynamic-field>
                <mt-dynamic-field [field]="endDateField" [form]="daterangeForm" class="end-date"></mt-dynamic-field>
                <br />
                <span *ngIf="hasDatesReversedError()" id="start-after-end-error" class="small errortext">Start must be earlier than end</span>
            </div>
        </form>
    </div>
</span>
