import { OpenNoteDynamicControls, IOpenNoteDynamicControlsParameters } from '../form-controls/open-note.form-controls';
import { IOpenNote } from '../interfaces/open-note';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

export class OpenNoteDynamicControlsPartial extends OpenNoteDynamicControls {
    constructor(opennotePartial?: IOpenNote, additionalParameters?: IOpenNoteDynamicControlsParameters) {
        super(opennotePartial, additionalParameters);

        (<DynamicField>this.Form.NoteText).type.inputType = InputTypes.Textarea;
    }
}
