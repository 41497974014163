import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicFieldTypes, DynamicLabel, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { formatPhone } from '@mt-ng2/format-functions';

/**
 * This is a view equivalent of dynamic-field
 */
@Component({
    selector: 'mt-dynamic-label',
    template: `
        <div class="display-padded">
            <b>{{ field.label }}:</b>
            <span [innerHtml]="getValue(field)"></span>
        </div>
    `,
})
export class DynamicLabelComponent {
    @Input() field: DynamicLabel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() options: any[];
    @Input() optionIdentifierName: string;
    @Input() optionValueName: string;

    defaultIdentifierName = 'Id';
    defaultValueName = 'Name';

    constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private percentPipe: PercentPipe, private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.defaultIdentifierName = this.optionIdentifierName || this.defaultIdentifierName;
        this.defaultValueName = this.optionValueName || this.defaultValueName;
    }

    getValue(field: DynamicLabel): string | SafeHtml {
        if (!field || (!field.value && field.value !== 0 && field.type.fieldType !== DynamicFieldTypes.Checkbox)) {
            return '';
        }
        if (field.valueHtml != null) {
            return this.sanitizer.bypassSecurityTrustHtml(field.valueHtml);
        }
        if (field.type.fieldType === DynamicFieldTypes.Numeric && field.type.inputType === NumericInputTypes.Currency) {
            return this.currencyPipe.transform(field.value as string);
        }
        if (field.type.fieldType === DynamicFieldTypes.Numeric && field.type.inputType === NumericInputTypes.Percentage) {
            return this.percentPipe.transform(field.value as string);
        }
        if (
            field.type.fieldType === DynamicFieldTypes.Input &&
            (field.type.inputType === InputTypes.Datepicker || field.type.inputType === InputTypes.DateTimeInput)
        ) {
            return this.datePipe.transform(field.value as string);
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.Phone) {
            return formatPhone(<string>field.value);
        }
        if (field.type.fieldType === DynamicFieldTypes.Checkbox) {
            return field.value ? 'Yes' : 'No';
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.DateInput) {
            return this.datePipe.transform(field.value as string, 'mediumDate', 'UTC');
        }
        if (field.type.fieldType === DynamicFieldTypes.Select && this.options?.length) {
            if (typeof field.value == 'object') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const selectedOptions = this.options.filter((o) => (field.value as unknown as number[]).includes(o.Id));
                return selectedOptions.map((o) => o[this.defaultValueName]).join(', ');
            } else {
                const option = this.options.find((o) => o[this.defaultIdentifierName] == field.value);
                return option ? option[this.defaultValueName] : field.value;
            }
        }
        return field.value.toString();
    }
}
