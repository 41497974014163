import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';
import { RGB, RGBToHex, HexToRGB, validRGB } from '../../library/hex-rgb.library';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'rgb-dynamic-cell',
    template: `
        <ng-container *ngIf="viewOnly">
            {{ displayData(item[itemProp]) }}
        </ng-container>

        <ng-container *ngIf="!viewOnly">
            <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                <span class="text-wrap">{{ displayData(item[itemProp]) }}</span
                >&nbsp;
                <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
            </div>
            <ng-container *ngIf="isEditing">
                <app-rgb [rgb]="rgb" (change)="rgbChange()" (keyDown)="onKeyPress($event)"></app-rgb>
                <save-cancel-buttons (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>
    `,
})
export class RGBInlineEditDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> {
    @ViewChild('firstInput') firstInput: ElementRef;
    @Input() rgb: RGB;

    constructor(injector: Injector, private notificationService: NotificationsService) {
        super(injector);
    }

    // Override
    onClick(event: any): boolean {
        setTimeout(() => this.firstInput?.nativeElement.focus());
        return super.onClick(event);
    }

    rgbChange(): void {
        this.clonedItem[this.itemProp] = RGBToHex(this.rgb);
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    validateData = (async (): Promise<boolean> => {
        if (!validRGB(this.rgb.red, this.rgb.blue, this.rgb.green)) {
            this.notificationService.error('RGB values must be between 0 and 255');
            return false;
        }
        return true;
    }).bind(this);

    displayData(hexCode: string): string {
        this.rgb = HexToRGB(hexCode);
        return `${!isNaN(this.rgb.red) ? this.rgb.red : ''}
        ${!isNaN(this.rgb.green) ? `, ${this.rgb.green}` : ''}
        ${!isNaN(this.rgb.blue) ? `, ${this.rgb.blue}` : ''}`;
    }
}
