import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'temp' })
export class TemperaturePipe implements PipeTransform {
    /**
     * @param convertTo should be either 'f' or 'c' to convert to Fahrenheit or Celcius respectively --
     * If invalid or not specified, returns value formatted as Fahrenheit
     */
    transform(temperature: number, convertTo?: string, formatted = true): string {
        if (temperature == null) {
            return 'N/A';
        }

        let newTemp = temperature;
        let format = 'F';
        if (convertTo?.toLowerCase() === 'c') {
            newTemp = this._convertToCelcius(temperature);
            format = 'C';
        } else if (convertTo?.toLowerCase() === 'f') {
            newTemp = this._convertToFahrenheit(temperature);
        }

        let toReturn = `${newTemp}`;
        if (formatted) {
            toReturn = `${parseFloat(newTemp.toFixed(1))} \xB0 ${format}`;
        }
        return toReturn;
    }

    private _convertToCelcius(tempurature: number): number {
        return ((tempurature - 32) * 5) / 9;
    }

    private _convertToFahrenheit(tempurature: number): number {
        return (tempurature * 9) / 5 + 32;
    }
}
