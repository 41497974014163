import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ILoginResponse } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LoginComponent, LoginConfig } from '@mt-ng2/login-module';
import { appPaths } from 'admin-portal/default-routes/app-paths.library';
import { throwError } from 'rxjs';

interface ILoginForm {
    password: FormControl<string | null>;
    username: FormControl<string | null>;
    rememberMe: FormControl<boolean | null>;
    rememberUserName: FormControl<boolean | null>;
}


@Component({
    selector: 'customer-portal-login',
    templateUrl: './customer-portal-login.component.html',
    styles: [
        `
            .portal-title {
                text-align: center;
                font-style: italic;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .btn-sign-in {
                background-color: #89b6ef;
                border-color: #89b6ef;
                color: black;
                outline: none;
            }
            .btn-sign-in:hover {
                background-color: #307ee2;
                border-color: #307ee2;
                outline: none;
                color: #333;
            }
            .portal-login-body {
                padding: 20px;
                border-top: 0;
                color: #666;
            }
            .tab-title {
                display: none !important;
            }
        `,
    ],
})
export class CustomerPortalLoginComponent extends LoginComponent {
    constructor(inj: Injector) {
        super(inj);
    }

    onLogin(): void {
        if (this.loginForm?.valid) {
            const values = this.loginForm.value;
            this.authService.login(values.username, values.password, values.rememberMe).subscribe({
                next: (loginResponse: ILoginResponse) => {
                    if (values.rememberUserName) {
                        this.rememberUserNameStorageService.saveRememberUserName(values.username);
                    } else {
                        this.rememberUserNameStorageService.removeUserNameCookie();
                    }
                    if (loginResponse.AuthUserId) {
                        this.notificationsService.info(
                            'A one-time password has been emailed to you.  Click the link in the email to finish logging in.',
                        );
                        void this.router.navigate([this.authConfig.paths.twoFactorVerifyPath]);
                    } else if (this.returnUrl) {
                        void this.router.navigateByUrl(this.returnUrl);
                    } else {
                        void this.router.navigate([this.authConfig.paths.homePath]);
                    }
                },
                error: (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 418) {
                        if (errorResponse.error === 'DomainLoginEmailSent') {
                            this.notificationsService.success('A login link has been emailed to you');
                        } else {
                            if (this.config.messageOverrides.userNamePasswordFailure) {
                                this.notificationsService.error(this.config.messageOverrides.userNamePasswordFailure);
                            } else {
                                this.notificationsService.error(LoginConfig.userNamePasswordFailure);
                            }
                        }
                        return undefined;
                    } else if (errorResponse.status === 403) {
                        if (errorResponse.error && typeof errorResponse.error === 'string') {
                            // if error message (i.e. "account is locked") then display it
                            this.notificationsService.error(errorResponse.error);
                            return undefined;
                        } else {
                            // else rethrow the error
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            return throwError(() => new Error(errorResponse.error));
                        }
                    } else if (errorResponse.status === 400) {
                        if (errorResponse.error) {
                            if (typeof errorResponse.error === 'string') {
                                this.notificationsService.error(errorResponse.error);
                            } else if (typeof errorResponse.error?.ModelState?.Service === 'string') {
                                this.notificationsService.error(errorResponse.error.ModelState.Service as string);
                            } else {
                                this.globalErrorHandler.handleError(errorResponse);
                            }
                        }
                        return undefined;
                    } else if (errorResponse.status === 307) {
                        void this.router.navigate([appPaths.loginFirstTime,], {
                            queryParams: {
                                authUserId: errorResponse.error.AuthUserId,
                                contactId: errorResponse.error.ContactId,
                                resetKey: errorResponse.error.ResetKey,
                            }
                        });
                        return undefined;
                    } else {
                        return undefined;
                    }
                },
            });
        } else {
            markAllFormFieldsAsTouched(this.loginForm as FormGroup<ILoginForm>);
        }
    }

}
