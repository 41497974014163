import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'entity-dropdown-create-dynamic-cell',
    template: `
        <entity-dropdown-dynamic-cell
            *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [dropdownService]="dropdownService"
            [itemDisplay]="itemDisplay"
            [optionDisplay]="optionDisplay"
            [viewOnly]="viewOnly"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
            (saved)="saved.emit($event)"
        ></entity-dropdown-dynamic-cell>

        <entity-dropdown
            *ngIf="!item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [dropdownService]="dropdownService"
            [optionDisplay]="optionDisplay"
        ></entity-dropdown>
    `,
})
export class EntityDropdownInlineCreateDynamicCell<T extends IEntity, S extends IEntity> extends InlineCreateDynamicCellBase<T> {
    @Input() dropdownService: any;
    @Input() itemDisplay = (item: T) => item.toString();
    @Input() optionDisplay = (option: S) => option.toString();
    @Output() saved = new EventEmitter<T>();
}
