import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'text-dynamic-cell',
    template: `
        <!-- View only (no edit) -->
        <ng-container *ngIf="viewOnly">
            {{ displayData(item[itemProp]) }}
        </ng-container>

        <!-- Editable -->
        <ng-container *ngIf="!viewOnly">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ displayData(item[itemProp]) }}</span>&nbsp;
                    <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                    <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
                </div>
                <ng-container *ngIf="isEditing">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons *ngIf="inputChanged" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <input #input class="form-control" [(ngModel)]="clonedItem[itemProp]" (keydown)="onKeyPress($event)" [placeholder]="placeholder">
        </ng-template>
    `,
})
export class TextInlineEditDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> {
    @ViewChild('input') input: ElementRef;
    @Input() displayData = (data: string) => data;
    @Input() placeholder = '';

    // Override
    onClick(event: any): boolean {
        setTimeout(() => this.input?.nativeElement.focus());
        return super.onClick(event);
    }

    onValidationFail(): void {
        setTimeout(() => this.input.nativeElement.focus());
    }

}
