<div [ngClass]="{ 'miles-card padded': panel, 'pb-0': usePaging && notes?.length > 0}">
    <h4>{{ title }}</h4>
    <div *ngIf="!isEditing">
        <ul class="list-group" style="max-height: {{maxHeight}}px; overflow-y: auto">
            <li class="list-group-item" *ngFor="let note of notes; last as isLast" [style.cursor]="'default'">
                <app-note-display [note]="note"></app-note-display>
                <scroll-check *ngIf="isLast" (inViewHasChanged)="onCheckInView($event)"></scroll-check>
            </li>
        </ul>
        <div *ngIf="canEdit" class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="createNote()">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div *ngIf="isEditing">
        <mt-dynamic-form [config]="config.formObject" (isEditing)="(isEditing)" (submitted)="formSubmitted($event)">
            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </mt-dynamic-form>
    </div>
    <mt-entity-list-pagination *ngIf="usePaging && notes?.length > 0"
        [total]="total"
        [(currentPage)]="currentPage"
        [itemsPerPage]="itemsPerPage"
        (pageChange)="getNotes()"
        >
    </mt-entity-list-pagination>
</div>
