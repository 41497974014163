import { DatePipe } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'date-dynamic-cell',
    template: `
        <ng-container *ngIf="viewOnly">
            {{ displayData(item[itemProp]) }}
        </ng-container>

        <ng-container *ngIf="!viewOnly">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ displayData(item[itemProp]) }}</span>&nbsp;
                    <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                    <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
                </div>
                <ng-container *ngIf="isEditing">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons *ngIf="inputChanged" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <app-date-picker [(date)]="clonedItem[itemProp]" [pastOnly]="pastOnly" (keydown)="onKeyPress($event)"></app-date-picker>
        </ng-template>
    `,
})
export class DateInlineEditDynamicCell<T extends IEntity> extends InlineEditDynamicCellBase<T> {
    @Input() pastOnly: boolean;
    private _dateFormat = 'M/d/yy';

    constructor(
        private datePipe: DatePipe,
        injector: Injector,
    ) {
        super(injector);
    }

    displayData(date: Date): string {
        return this.datePipe.transform(date, this._dateFormat);
    }
}
