import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'checkbox-create-dynamic-cell',
    template: `
        <checkbox-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [viewOnly]="viewOnly"
            [checkboxTitle]="checkboxTitle"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
        ></checkbox-dynamic-cell>

        <ng-container *ngIf="!item.Id">
            <simple-checkbox [(checked)]="item[itemProp]" [checkboxTitle]="checkboxTitle"></simple-checkbox>
        </ng-container>
    `,
})
export class CheckboxInlineCreateDynamicCell<T extends IEntity> extends InlineCreateDynamicCellBase<T> {
    @Input() checkboxTitle = 'Archive';
}
