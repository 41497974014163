import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'simple-dropdown-create-dynamic-cell',
    template: `
        <simple-dropdown-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [options]="options"
            [service]="service"
            [viewOnly]="viewOnly"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
        ></simple-dropdown-dynamic-cell>

        <simple-dropdown *ngIf="!item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [options]="options"
            [service]="service"
        ></simple-dropdown>
    `,
})
export class SimpleDropdownInlineCreateDynamicCell<T extends IEntity, S> extends InlineCreateDynamicCellBase<T> {
    @Input() options: { value: S, display: string }[];
}
