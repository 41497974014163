import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent, ResetPasswordComponent, AdminAccessComponent } from '@mt-ng2/login-module';
import { CustomerPortalLoginComponent } from './customer-portal/login/customer-portal-login.component';
import { AdminPortalLoginComponent } from 'admin-portal/login/admin-portal-login.component';

import { appPaths } from './admin-portal/default-routes/app-paths.library';
import { MtCanLoadAuthGuard } from '@mt-ng2/auth-module';
import { environment } from '@environment';
import { ErpAccessComponent } from './customer-portal/base/erp-access.component';
import { erpAccessSlugs } from './customer-portal/default-routes/erp-access.library';
import { CustomerPortalLoginFirstTimeComponent } from 'customer-portal/login/login-first-time/customer-portal-login-first-time.component';

const adminPortalRoutes: Routes = [
    { path: appPaths.login, component: AdminPortalLoginComponent, title: 'Login' },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent, title: 'Forgot Password' },
    { path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
    { path: appPaths.adminAccess, component: AdminAccessComponent, title: 'Admin Access' },
    { canLoad: [MtCanLoadAuthGuard], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule) },
];

const customerPortalRoutes: Routes = [
    { path: erpAccessSlugs.erpAccess, component: ErpAccessComponent },
    { path: appPaths.login, component: CustomerPortalLoginComponent },
    { path: appPaths.loginFirstTime, component: CustomerPortalLoginFirstTimeComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent },
    { path: appPaths.resetPassword, component: ResetPasswordComponent },
    { path: appPaths.adminAccess, component: AdminAccessComponent },
    {
        canLoad: [MtCanLoadAuthGuard],
        path: '',
        loadChildren: () => import('./customer-portal/customer-portal.module').then((m) => m.CustomerPortalModule),
    },
];

const isAdminDomain = window.location.host === environment.adminDomain;

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(isAdminDomain ? adminPortalRoutes : customerPortalRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
