import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '@model/interfaces/address';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'cityAndState' })
export class CityAndStatePipe implements PipeTransform {

    transform(address: IAddress): string {
        if (address) {
            return `${address.City}, ${address.StateCode}`;
        }
        return '';
    }
}
