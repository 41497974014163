import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'number-create-dynamic-cell',
    template: `
        <number-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [viewOnly]="viewOnly"
            [validateData]="validateData"
            [displayData]="displayData"
            [transformData]="transformData"
            [min]="min"
            [max]="max"
            [placeholder]="placeholder"
            [requiredEditClaimType]="requiredEditClaimType"
            [allowZeroValue]="true"
            [useUpdateWithFks]="useUpdateWithFks"
        ></number-dynamic-cell>

        <ng-container *ngIf="!item.Id">
            <input #input
                class="form-control min-three-digits"
                type="number"
                [min]="min"
                [max]="max"
                [(ngModel)]="item[itemProp]"
                (ngModelChange)="transformData($event)"
                [placeholder]="placeholder"
            >
        </ng-container>
    `,
})
export class NumberInlineCreateDynamicCell<T extends IEntity> extends InlineCreateDynamicCellBase<T> {
    @Input() min = 1;
    @Input() max: number;
    @Input() placeholder = '';
}
