import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-date-picker',
    template: `
        <input
            type="date"
            class="form-control"
            [value]="date | date : 'yyyy-MM-dd'"
            (input)="parseDate($any($event).target.value)"
            [max]="pastOnly ? (today | date : 'yyyy-MM-dd') : ''"
        />
    `,
})
export class DatePicker implements OnInit {
    @Input() date: Date;
    @Input() pastOnly: boolean;
    @Output('dateChange') dateChangeEmitter = new EventEmitter<Date>();

    today = new Date();

    ngOnInit(): void {
        if (!this.date) {
            this.date = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0, 0);
            this.dateChangeEmitter.emit(this.date);
        }
    }

    parseDate(dateString: string): void {
        if (dateString) {
            let parsedDate = new Date(dateString + ' 00:00:00');
            if (this.pastOnly && parsedDate > this.today) {
                parsedDate = this.today;
            }
            this.dateChangeEmitter.emit(parsedDate);
        }
    }
}
