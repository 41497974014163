import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { IUser } from '@model/interfaces/user';

@Component({
    selector: 'history-popup-dynamic-cell',
    styleUrls: ['./history-popup.dynamic-cell.less'],
    templateUrl: './history-popup.dynamic-cell.html',
})
export class HistoryPopupDynamicCell<T extends IEntity> {
    @Input() item: T;
    @Input() title: string;
    modal: IModalWrapperApi;
    users: IUser[];

    get createdBy(): IUser { return (<any> this.item).CreatedBy; }
    get modifiedBy(): IUser { return (<any> this.item).ModifiedBy || null; }
    get dateCreated(): Date { return (<any> this.item).DateCreated; }
    get dateModified(): Date { return (<any> this.item).DateModified || null; }

    constructor(
        public datePipe: DatePipe,
    ) {}

    openPopup(e: Event): void {
        e.stopPropagation();
        this.modal.show();
    }
}
