import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'save-cancel-buttons',
    template: `
        <div class="text-nowrap">
            <button *ngIf="showSaveBtn" class="btn btn-primary mt-2" (click)="saveEmitter.emit()"><i class="fa fa-check"></i></button>
            <button class="btn btn-default mt-2" (click)="cancelEmitter.emit()"><i class="fa fa-times"></i></button>
        </div>
    `,
})
export class SaveCancelButtonsComponent {
    @Input() showSaveBtn = true;
    @Output('save') saveEmitter = new EventEmitter();
    @Output('cancel') cancelEmitter = new EventEmitter();
}
