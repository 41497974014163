import { Component, Input } from '@angular/core';
import { IEntity, IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import Swal from 'sweetalert2';

@Component({
    selector: 'delete-dynamic-cell',
    template: `
        <div class="btn btn-primary" (click)="deleteClick($event)" [class.disabled]="disabled">
            <i class="fa {{ faIcon }}"></i>
        </div>
    `,
})
export class DeleteDynamicCell<T extends IEntity> implements IEntityListDynamicCellComponentGeneric<T> {
    @Input() entityListComponentMembers: IEntityListComponentMembers;
    @Input() disabled: boolean;
    @Input() disabledMessage: string;
    @Input() showWarn = true;
    @Input() faIcon = 'fa-trash';
    @Input() title = 'Are you sure you want to delete this?';
    @Input() text: string;
    @Input() set entity(value: T) {
        this.item = value;
    }
    item: T;

    constructor(private notificationService: NotificationsService) {}

    deleteClick(e: Event): void {
        e.stopPropagation();
        if (this.showWarn) {
            this._deleteWithWarn(e);
        } else {
            this._deleteNoWarn(e);
        }
    }

    private _deleteWithWarn(e: Event): void {
        if (!this.disabled) {
            void Swal.fire({
                icon: 'warning',
                title: this.title,
                text: this.text,
                confirmButtonText: '<i class="fa fa-trash"></i>&nbsp;&nbsp;Delete',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.entityListComponentMembers.itemDeleted(this.item, e);
                }
            });
        } else {
            this.notificationService.warning(this.disabledMessage || 'Cannot delete');
        }
    }

    private _deleteNoWarn(e: Event): void {
        this.entityListComponentMembers.itemDeleted(this.item, e);
    }
}
