import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-email-note',
    templateUrl: './email-note.component.html',
})
export class EmailNoteComponent implements OnInit {
    @Input() title = 'Custom Email Note';
    @Input() customerName: string;
    @Input() shipToName: string;
    @Output() emailNoteEvent = new EventEmitter<string>();
    @Output() onCancelEvent = new EventEmitter<void>();

    emailNoteModal: IModalWrapperApi;
    modalOptions: IModalOptions;
    emailNote: string;
    customerAndShipToNames: string;

    constructor() {
        this.modalOptions = {
            showCancelButton: true,
            allowOutsideClick: true,
            confirmButtonText: 'Send Email',
        };
    }

    ngOnInit(): void {
        // bulk ready to ship email probably won't have a single customer
        if (this.customerName?.length > 0) {
            this.customerAndShipToNames = this.shipToName?.length > 0 ? `to ${this.customerName} / ${this.shipToName}` : `to ${this.customerName}`;
        }
    }

    onSubmitButtonClick(): void {
        this.emailNoteEvent.emit(this.emailNote);
        this.emailNoteModal.close();
    }

    onCancelClick(): void {
        this.emailNoteModal.close();
        this.onCancelEvent.emit();
    }
}
