import { Component, Input } from '@angular/core';
import { INote } from '@model/interfaces/note';

@Component({
    selector: 'app-note-display',
    styles: [
        `
            .note-display {
                min-width: 230px;
                max-width: 1000px;
            }
            .note-creation-details {
                text-align: left;
                font-size: 0.9em;
            }
        `,
    ],
    template: `
        <div class="note-display">
            <div style="text-align: left">
                <span> {{ note.NoteText }}</span>
            </div>
            <div class="note-creation-details">
                <span>{{ note.DateCreated | date : 'medium' }}</span>
            </div>
            <div class="note-creation-details">
                <span>{{ note.User | fullName }}</span>
            </div>
        </div>
    `,
})
export class NoteDisplayComponent {
    @Input() note: INote;
}
