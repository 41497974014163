import { DynamicField } from '@mt-ng2/dynamic-form';
import { NoteDynamicControls, INoteDynamicControlsParameters } from '../form-controls/note.form-controls';
import { INote } from '../interfaces/note';

export class NoteDynamicControlsPartial extends NoteDynamicControls {
    constructor(notePartial?: INote, additionalParameters?: INoteDynamicControlsParameters) {
        super(notePartial, additionalParameters);

        (<DynamicField>this.Form.NoteText).labelHtml = '<label>Note</label>';
    }
}
