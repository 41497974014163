import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';

@Component({
    selector: 'date-create-dynamic-cell',
    template: `
        <date-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [viewOnly]="viewOnly"
            [pastOnly]="pastOnly"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
        ></date-dynamic-cell>

        <ng-container *ngIf="!item.Id">
            <app-date-picker [(date)]="item[itemProp]" [pastOnly]="pastOnly"></app-date-picker>
        </ng-container>
    `,
})
export class DateInlineCreateDynamicCell<T extends IEntity> extends InlineCreateDynamicCellBase<T> {
    @Input() pastOnly: boolean;
}
