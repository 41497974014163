import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IOpenNote } from '@model/interfaces/open-note';
import { Observable } from 'rxjs';

export const emptyOpenNote: IOpenNote = {
    CreatedById: 0,
    DateCreated: new Date(),
    EntityId: 0,
    NoteText: null,
    OpenNoteTypeId: 0,
};

@Injectable({
    providedIn: 'root',
})
export abstract class OpenNoteService {
    constructor(public route: string, public http: HttpClient) {}

    getEmptyOpenNote(): IOpenNote {
        return { ...emptyOpenNote };
    }

    get(entityId: number, typeId: number): Observable<IOpenNote> {
        return this.http.get<IOpenNote>(`/${this.route}/${entityId}/open-notes/${typeId}`);
    }

    createOrUpdate(entityId: number, typeId: number, note: IOpenNote): Observable<IOpenNote> {
        return this.http.post<IOpenNote>(`/${this.route}/${entityId}/open-notes/${typeId}`, note);
    }
}
