import { NgModule } from '@angular/core';
import { MtLoginModule } from '@mt-ng2/login-module';
import { CustomerPortalLoginComponent } from './customer-portal-login.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';
import { CustomerPortalLoginFirstTimeComponent } from './login-first-time/customer-portal-login-first-time.component';

@NgModule({
    declarations: [CustomerPortalLoginComponent, CustomerPortalLoginFirstTimeComponent],
    exports: [CustomerPortalLoginComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MtDisableDuringHttpCallsModule, MtLoginModule, AdminPortalFeatureModule, AdminPortalSharedModule],
})
export class CustomerPortalLoginModule {}
