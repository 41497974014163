import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'mt-single-filter-select',
    template: `
        <mt-search-filter-select
            [items]="items"
            [showSelectAllButtons]="false"
            [entity]="entity"
            (selectionChanged)="onSelectionChanged()">
        </mt-search-filter-select>
    `,
})
export class MtSingleFilterSelectComponent implements OnInit {
    @Input() items: MtSearchFilterItem[] = [];
    @Input() entity: string;
    @Output() selectionChanged = new EventEmitter();
    currentSelectionId: number;

    ngOnInit(): void {
        this.currentSelectionId = this.items.findIndex((x) => x.Selected);
    }

    onSelectionChanged(): void {
        this.currentSelectionId >= 0 && (this.items[this.currentSelectionId].Selected = false);
        this.currentSelectionId = this.items.findIndex((x) => x.Selected);
        this.selectionChanged.emit();
    }
}
