import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ISavedSearch } from '@model/interfaces/saved-search';
import { SavedSearchPages } from '@model/enums/saved-search-pages.enum';

@Injectable({ providedIn: 'root' })
export class SavedSearchService {
    emptySavedSearch: ISavedSearch = {
        SavedSearchPageId: 0,
        Search: '',
        IsDefault: false,
        Name: '',
        Id: 0,
        UserId: 0,
    };

    constructor(private http: HttpClient) {}

    getNewSavedSearch(savedSearchPageId: SavedSearchPages): ISavedSearch {
        return { ...this.emptySavedSearch, SavedSearchPageId: savedSearchPageId };
    }

    getSavedSearches(searchId: SavedSearchPages): Observable<ISavedSearch[]> {
        return this.http.get<ISavedSearch[]>(`/saved-search/${searchId}`);
    }

    saveSearch(searchId: SavedSearchPages, search: ISavedSearch): Observable<ISavedSearch[]> {
        return this.http.post<ISavedSearch[]>(`/saved-search/${searchId}`, search);
    }

    deleteSearch(searchId: SavedSearchPages, savedSearchId: number): Observable<ISavedSearch[]> {
        return this.http.delete<ISavedSearch[]>(`/saved-search/${searchId}/${savedSearchId}`);
    }
}
