<mt-modal-wrapper
    [autoShow]="true"
    (ready)="emailNoteModal = $event"
    [title]="title"
    [options]="modalOptions"
    (okClick)="onSubmitButtonClick()"
    (cancelClick)="onCancelClick()"
>
    <div>
        Please add additional comments you would like to include in the body of your email {{ customerAndShipToNames }}
        <br />
        <br />
        <textarea maxlength="5000" class="form-control note-textarea" [(ngModel)]="emailNote"></textarea>
    </div>
</mt-modal-wrapper>
