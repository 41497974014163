import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { EntityListComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'drag-drop-entity-list',
    templateUrl: 'drag-and-drop-entity-list.component.html',
    styles: [
        `
            .cdk-drag-preview {
                box-sizing: border-box;
                border-radius: 4px;
                box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            }
        `,

        `
            .cdk-drag-placeholder {
                opacity: 0;
            }
        `,

        `
            .cdk-drag-animating {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
        `,
        `
            .drag-text {
                margin: 5px;
            }
        `,
    ],
})
export class DragAndDropEntityListComponent extends EntityListComponent implements IEntityListComponentMembers {
    /**
     * The columns to display in the drag preview.
     */
    @Input() previewDisplayColumnNames: string[];
    /**
     * Emits when the list order is updated, source list will reflect new order
     */
    @Output() onListOrderChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(injector: Injector) {
        super(injector);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drop(event: CdkDragDrop<any, any>): void {
        this.currentEntities.splice(event.currentIndex, 0, this.currentEntities.splice(event.previousIndex, 1)[0]);
        this.entities = this.currentEntities;
        this.onListOrderChanged.emit(true);
    }
}
