<ng-select
    class="ng-select-as-filter mt-2 mb-1"
    [items]="items"
    [multiple]="true"
    [closeOnSelect]="false"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [placeholder]="placeholder"
    [clearSearchOnAdd]="clearSearchOnAdd"
    [ngModel]="selectedIds"
    [virtualScroll]="virtualScroll"
    (scrollToEnd)="loadMore()"
    (ngModelChange)="changeEmitter.emit($event)"
    (add)="onAdd()"
    (search)="onSearch($event)"
    (clear)="onClear()"
    (remove)="onRemove()"
>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> All
    </ng-template>
    <ng-template ng-header-tmp *ngIf="selectAllSubset">
        <input id="selectAll" type="checkbox" [(ngModel)]="selectAll" (ngModelChange)="selectAllItems()" /> Select All
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item[bindLabel] }}
    </ng-template>
</ng-select>
