/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditDynamicCellBase } from './base/inline-edit.dynamic-cell.base';

@Component({
    selector: 'entity-dropdown-dynamic-cell',
    template: `
        <!-- View only (no edit) -->
        <ng-container *ngIf="viewOnly">
            {{ itemDisplay(item) }}
        </ng-container>

        <!-- Editable -->
        <ng-container *ngIf="!viewOnly">
            <ng-container *ngIf="!alwaysOpen">
                <div *ngIf="!isEditing" (click)="onClick($event)" class="pointer text-nowrap">
                    <span class="text-wrap">{{ itemDisplay(item) }}</span
                    >&nbsp;
                    <a *ngIf="item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
                    <a *ngIf="!item[itemProp]" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
                </div>
                <ng-container *ngIf="isEditing">
                    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                    <save-cancel-buttons *ngIf="!saveOnBlur" [showSaveBtn]="showSaveBtn" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="alwaysOpen">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
                <save-cancel-buttons *ngIf="inputChanged && !saveOnBlur" [showSaveBtn]="showSaveBtn" (save)="subscribeSave()" (cancel)="cancel()"></save-cancel-buttons>
            </ng-container>
        </ng-container>

        <ng-template #inputTemplate>
            <entity-dropdown
                [item]="clonedItem"
                [itemProp]="itemProp"
                [service]="service"
                [dropdownService]="dropdownService"
                [optionDisplay]="optionDisplay"
                (keyPressed)="onKeyPress($event)"
                (optionSelected)="onOptionSelected($event)"
            ></entity-dropdown>
        </ng-template>
    `,
})
export class EntityDropdownInlineEditDynamicCell<T extends IEntity, S extends IEntity> extends InlineEditDynamicCellBase<T> {
    /** A function which takes an item and returns a string containing the desired display */
    @Input() itemDisplay = (item: T) => item.toString();

    // Passed to component
    @Input() service: any;
    @Input() dropdownService: any;
    @Input() showSaveBtn = true;
    @Input() optionDisplay = (option: S) => option.toString();

    @Output() inputValueChanged = new EventEmitter<T>();

    _saveId = true;

    // Override
    get inputChanged(): boolean {
        return this.clonedItem[this.itemProp + 'Id'] !== this.item[this.itemProp + 'Id'];
    }

    // Override
    cancel(): void {
        super.cancel();
        // Reset the entity dropdown
        this.clonedItem = JSON.parse(JSON.stringify(this.item));
        this.sendUpdatedValue(this.clonedItem);
    }

    onOptionSelected(value: any): void {
        if (this.saveOnBlur) {
            this.subscribeSave();
            return;
        }
        this.sendUpdatedValue(this.clonedItem);
    }

    sendUpdatedValue(item: T): void {
        this.inputValueChanged.emit(item);
    }
}
