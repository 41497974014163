<ng-container>
    <div class="row">
        <div class="col-md-12">
            <div class="saved-search-bar">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div *ngIf="searches?.length" class="multiselect-typeahead">
                            <ng-select
                                #input
                                [items]="searches"
                                bindLabel="Name"
                                [(ngModel)]="selectedSearch"
                                (change)="searchSelected()"
                                placeholder="Select saved search"
                            >
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    {{ item.Name }} <span *ngIf="item.IsDefault">*</span>
                                </ng-template>
                            </ng-select>
                        </div>
                        <span *ngIf="!searches?.length">
                            <em>No Saved Searches</em>
                        </span>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <span class="pull-right" *ngIf="selectedSearch">
                            <input
                                class="form-control input-inline mr-1"
                                type="text"
                                [(ngModel)]="selectedSearch.Name"
                                placeholder="Enter a name for the search"
                            />
                            <input id="set-new-default" type="checkbox" [(ngModel)]="selectedSearch.IsDefault" /> Default
                            <button type="button" class="btn btn-success mr-1 ml-1" (click)="saveSearch()">Save Search</button>
                            <app-icon-button
                                *ngIf="selectedSearch.Id"
                                iconString="fa fa-trash-o"
                                tooltipText="Delete saved search"
                                (onClick)="deleteSearch()"
                                [(disabled)]="disableDeleteButton"
                            ></app-icon-button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
</ng-container>
