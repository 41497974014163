<div *ngIf="!isNonSaving" (click)="onClick($event)">
    <div *ngIf="item[itemProp]" class="notes-grid">
        <div class="clip-text">{{ item[itemProp] }}</div>
        <a class="edit-icon"><i class="fa fa-fw fa-eye"></i></a>
    </div>
    <a *ngIf="!item[itemProp] && !viewOnly" class="edit-icon"><i class="fa fa-fw fa-plus"></i></a>
</div>

<div *ngIf="isNonSaving" (click)="onClick($event)">
    <div *ngIf="item[itemProp]" class="notes-grid">
        <div class="clip-text">{{ item[itemProp] }}</div>
        <a class="edit-icon"><i class="fa fa-fw fa-edit"></i></a>
    </div>
    <button *ngIf="!item[itemProp] && !viewOnly" class="btn btn-primary"><i class="fa fa-fw fa-plus"></i></button>
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [allowOutsideClick]="false"
    [showActions]="false"
    (ready)="modal = $event"
>
    <h2 class="popup-title">Notes for {{ title }}</h2>

    <ng-container *ngIf="viewOnly">
        <textarea #input disabled type="textarea" class="popup-textarea form-control my-4" [ngModel]="item[itemProp]"></textarea>
        <button class="btn btn-secondary" (click)="cancel()">Close</button>
    </ng-container>

    <ng-container *ngIf="!viewOnly">
        <textarea *ngIf="!isNonSaving" #input type="textarea" class="popup-textarea form-control my-4" [(ngModel)]="clonedItem[itemProp]" (keydown)="onKeyPress($event)"></textarea>
        <textarea *ngIf="isNonSaving" #input type="textarea" class="popup-textarea form-control my-4" [(ngModel)]="item[itemProp]" (keydown)="onKeyPress($event)"></textarea>
        <ng-container *ngIf="!inputEdited">
            <button class="btn btn-primary" (click)="modal.close()">Close</button>
        </ng-container>
        <ng-container *ngIf="inputEdited">
            <button class="btn btn-primary" (click)="subscribeSave()">{{ isNonSaving ? 'Ok' : 'Save' }}</button>
            <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
        </ng-container>
    </ng-container>
</mt-modal-wrapper>
