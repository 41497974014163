import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { INote } from '@model/interfaces/note';
import { NoteDynamicConfig } from './note.dynamic-config';

import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NoteService } from './note.service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IUser } from '@model/interfaces/user';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
    @Input() entityId: number;
    @Input() typeId: number;
    @Input() canEdit: boolean;
    @Input() title: string;
    @Input() noteService: NoteService;
    @Input() panel = true;
    @Input() itemsPerPage = 3;
    @Input() maxHeight = 195;
    @Input() usePaging = false;

    isEditing = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any = { formObject: [], viewOnly: [] };
    formFactory: NoteDynamicConfig<INote>;
    notes: INote[];
    newNote: INote;
    currentUser: ILoggedIn;
    currentPage = 1;
    query = '';
    freshList = true;
    allFetched: boolean;
    total: number;

    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    constructor(private authService: AuthService, private notificationsService: NotificationsService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.getNotes();
        this.authService.currentUser.subscribe((user) => (this.currentUser = user));
    }

    getNotes(): void {
        const searchParams = this.buildSearch();
        this.noteService.getNotes(this.entityId, searchParams).subscribe((resp) => {
            if (this.usePaging) {
                this.notes = resp.body;
                this.total = +resp.headers.get('X-List-Count');
                this.cdr.detectChanges();
            } else {
                if (resp.body.length === 0) {
                    this.allFetched = true;
                }
                if (this.freshList) {
                    this.notes = resp.body;
                    this.freshList = false;
                    this.cdr.detectChanges();
                } else {
                    this.notes.push(...resp.body);
                    this.cdr.detectChanges();
                }
            }
        });
    }

    buildSearch(): SearchParams {
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({
                name: 'NoteTypeId',
                valueArray: [this.typeId],
            }),
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'DateCreated',
            orderDirection: 'desc',
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        return new SearchParams(searchEntity);
    }

    search(query: string): void {
        this.setListAsRefresh();
        this.query = query;
        this.getNotes();
    }

    setListAsRefresh(): void {
        this.currentPage = 1;
        this.allFetched = false;
        this.freshList = true;
    }

    setConfig(configControls?: string[]): void {
        const users: IUser[] = null;
        this.formFactory = new NoteDynamicConfig<INote>(this.newNote, users, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    createNote(): void {
        this.newNote = this.noteService.getEmptyNote(this.typeId);
        this.setConfig();
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.newNote, form.value.Note as INote);
            this.newNote.UserId = this.currentUser.Id;
            this.newNote.DateCreated = new Date();
            this.saveNote();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    onCheckInView(inView: boolean): void {
        // scroll-check will be in view before notes load, we don't want to pull on initial load
        // We also don't want to pull the list if coming back from editing since success will do that
        if (inView && !this.freshList && !this.allFetched) {
            this.currentPage++;
            this.getNotes();
        }
    }

    private saveNote(): void {
        this.noteService
            .saveNote(this.entityId, this.newNote)
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.setListAsRefresh();
        this.getNotes();
        this.isEditing = false;
        this.setConfig();
        this.noteService.emitChange(this.newNote);
        this.notificationsService.success('Note saved successfully.');
        this.cdr.detectChanges();
    }
}
