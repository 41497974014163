import { Pipe, PipeTransform } from '@angular/core';
import { ICustomerContact } from '@model/interfaces/customer-contact';
import { IUser } from '@model/interfaces/user';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    transform(user: IUser | ICustomerContact): string {
        return user ? `${user.FirstName} ${user.LastName}` : '';
    }
}
