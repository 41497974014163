import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { INote } from '@model/interfaces/note';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyNote: INote = {
    NoteTypeId: 0,
    DateCreated: new Date(),
    Id: 0,
    NoteText: null,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export abstract class NoteService extends BaseService<INote> {
    constructor(public route: string, public http: HttpClient) {
        super('/', http);
    }

    getEmptyNote(typeId: number): INote {
        return { ...emptyNote, NoteTypeId: typeId };
    }

    getNotes(parentId: number, searchParams: SearchParams): Observable<HttpResponse<INote[]>> {
        const params = this.getHttpParams(searchParams);

        return this.http
            .get<INote[]>(`/${this.route}/${parentId}/notes/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    saveNote(parentId: number, note: INote): Observable<number> {
        if (!note.Id) {
            note.Id = 0;
            return this.http.post<number>(`/${this.route}/${parentId}/notes`, note);
        } else {
            return this.http.put<number>(`/${this.route}/${parentId}/notes`, note, { responseType: 'text' as 'json' });
        }
    }

    deleteNote(parentId: number, noteId: number): Observable<object> {
        return this.http.delete(`/${this.route}/${parentId}/notes/${noteId}`, {
            responseType: 'text' as 'json',
        });
    }
}
