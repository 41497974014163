import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RGB } from '../../library/hex-rgb.library';

@Component({
    selector: 'app-rgb',
    template: `
        <ng-container *ngIf="rgb">
            <input #firstInput
                type="number"
                min="1" max="255"
                class="form-control my-1"
                [(ngModel)]="rgb.red"
                (ngModelChange)="rgbChange()"
                [placeholder]="'Red'"
                (keydown)="onKeyPress($event)"
            >
            <input type="number"
                min="1" max="255"
                class="form-control my-1"
                [(ngModel)]="rgb.green"
                (ngModelChange)="rgbChange()"
                [placeholder]="'Green'"
                (keydown)="onKeyPress($event)"
            >
            <input type="number"
                min="1" max="255"
                class="form-control my-1"
                [(ngModel)]="rgb.blue"
                (ngModelChange)="rgbChange()"
                [placeholder]="'Blue'"
                (keydown)="onKeyPress($event)"
            >
        </ng-container>
    `,
})
export class RGBComponent {
    @Input() rgb: RGB;
    @Output('change') rgbChangeEmitter = new EventEmitter();
    @Output('keyDown') keyDownEmitter = new EventEmitter();

    rgbChange(): void {
        this.rgbChangeEmitter.emit();
    }

    onKeyPress(e: KeyboardEvent): void {
        this.keyDownEmitter.emit(e);
    }
}
