import { Component, Input } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';

@Component({ template: `` })
export abstract class InlineCreateDynamicCellBase<T extends IEntity> {
    @Input() item: T;
    @Input() itemProp: string;
    @Input() service: any;
    @Input() viewOnly = false;
    // eslint-disable-next-line @typescript-eslint/require-await
    @Input() validateData = async (data: T) => true;
    @Input() displayData = (data: string) => data;
    @Input() transformData = (data: any) => data;

    @Input() requiredEditClaimType: number;
    @Input() useUpdateWithFks = false;
}
