import { Injectable } from '@angular/core';
import { AuthService, ILoginResponse } from '@mt-ng2/auth-module';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ExtendedAuthService extends AuthService {
    erpAccessLogin(token: string, customerId: string, authUserId: string): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            AuthUserId: authUserId,
            TokenIdentifier: token,
            CustomerId: customerId,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/customer-portal-access', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.saveToken(response, false)));
    }
}
