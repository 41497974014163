import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineEditBaseService } from '../../../services/inline-edit.base-service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { EntityTrackerService } from '../../../services/entity-tracker-service';

@Component({ template: `` })
export abstract class InlineEditDynamicCellBase<T extends IEntity> implements OnInit {
    isEditing: boolean;
    clonedItem: T;
    /** If true, item[itemProp] will be treated as an IEntity whose Id must be copied to item[itemProp + 'Id'] before saving */
    protected _saveId = false;

    @Input() item: T;
    /** The property of 'item' to display in the input field */
    @Input() itemProp: string;
    /** The property of 'item' that must be edited first (for a new item) */
    @Input() requiredProp: string;
    /** The service that will be used to save or create entities */
    @Input() service: InlineEditBaseService<T>;
    /** The message to display when data validation fails */
    @Input() validationErrorMessage: string;
    /** A function that should transform the data as desired */
    @Input() transformData = (data: any) => data;
    /** A function that should return true when the data is formatted as desired */
    // eslint-disable-next-line @typescript-eslint/require-await
    @Input() validateData = async (arg0: T) => true;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected onValidationFail(): void {}
    /** A flag that denotes whether the dynamic cell should always show the edited view */
    @Input() alwaysOpen = false;
    /** A flag that denotes whether to save when a user blurs focus of input(s) */
    @Input() saveOnBlur = false;
    @Input() suppressSaveNotification = false;

    @Input() viewOnly = false;
    @Input() requiredEditClaimType: number;
    @Input() useUpdateWithFks = false;
    @Output() saved = new EventEmitter<T>();

    get inputChanged(): boolean {
        return this.clonedItem[this.itemProp] !== this.item[this.itemProp];
    }

    private _notificationServiceInner: NotificationsService;
    private _claimService: ClaimsService;
    private _cdr: ChangeDetectorRef;
    private _entityTrackerService: EntityTrackerService;
    constructor(injectorInner: Injector) {
        this._notificationServiceInner = injectorInner.get(NotificationsService);
        this._claimService = injectorInner.get(ClaimsService);
        this._cdr = injectorInner.get(ChangeDetectorRef);
        this._entityTrackerService = injectorInner.get(EntityTrackerService);
    }

    ngOnInit(): void {
        this.clonedItem = JSON.parse(JSON.stringify(this.item));
        if (this.requiredEditClaimType) {
            this.viewOnly = this.viewOnly || !this._claimService.hasClaim(this.requiredEditClaimType, [ClaimValues.FullAccess]);
        }
    }

    onClick(event: any): boolean {
        event.stopPropagation();
        if (!this.requiredProp || this.item[this.requiredProp] || this.requiredProp === this.itemProp) {
            this.clonedItem = JSON.parse(JSON.stringify(this.item));
            this.isEditing = true;
            return true;
        } else {
            this._notificationServiceInner.warning(`'${this.requiredProp}' is required. Please enter it first`);
            return false;
        }
    }

    onKeyPress(e: KeyboardEvent): void {
        if (e.key === 'Enter' || e.key === 'Tab') {
            this.subscribeSave();
        }
        if (e.key === 'Escape') {
            this.cancel();
        }
    }

    subscribeSave(): void {
        void this.save().then((obs) => obs.subscribe());
    }

    async save(): Promise<Observable<any>> {
        this.clonedItem[this.itemProp] = this.transformData(this.clonedItem[this.itemProp]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (await this.validateData(this.clonedItem)) {
            let backEndCall: Observable<any>;
            if (this.item.Id) {
                if (this.useUpdateWithFks) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    backEndCall = this.service.updateWithFks(this.clonedItem);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    backEndCall = this.service.update(this.clonedItem);
                }
                backEndCall = backEndCall.pipe(
                    tap((updatedItem: T) => {
                        (<any>updatedItem)?.DateModified && ((<any>this.item).DateModified = (<any>updatedItem).DateModified);
                        !this.suppressSaveNotification && this._notificationServiceInner.success('Saved successfully');
                        this._entityTrackerService.$updatedSuccessfully.next(true);
                        this.saved.emit(updatedItem);
                    }),
                );
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                backEndCall = this.service.refreshListCreate(this.clonedItem).pipe(
                    tap((id: number) => {
                        this.item.Id = id;
                        !this.suppressSaveNotification && this._notificationServiceInner.success('Created successfully');
                        this.saved.emit(this.clonedItem);
                    }),
                );
            }
            return backEndCall.pipe(
                tap(
                    () => {
                        this.item[this.itemProp] = this.clonedItem[this.itemProp];
                        if (this._saveId) {
                            this.item[this.itemProp + 'Id'] = this.clonedItem[this.itemProp].Id;
                        }
                        this.isEditing = false;
                        this._cdr.markForCheck();
                    },
                    () => {
                        // When failing to update, if saving on blur, revert the value since there is no confirm button
                        if (this.saveOnBlur) {
                            this._revertToOriginal();
                        }
                    },
                ),
            );
        } else {
            this.onValidationFail();
            this.validationErrorMessage && this._notificationServiceInner.warning(this.validationErrorMessage);
            if (this.saveOnBlur) {
                this._revertToOriginal();
            }
            // Do not emit -- leave 'of' empty
            return of();
        }
    }

    private _revertToOriginal(): void {
        this.clonedItem[this.itemProp] = this.item[this.itemProp];
        if (this._saveId) {
            this.clonedItem[this.itemProp].Id = this.item[this.itemProp + 'Id'];
        }
        this._cdr.markForCheck();
    }

    cancel(): void {
        this.clonedItem[this.itemProp] = this.item[this.itemProp];
        this._cdr.markForCheck();
        this._cdr.detectChanges();
        this.isEditing = false;
    }
}
