import { Component } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { InlineCreateDynamicCellBase } from './base/inline-create.dynamic-cell.base';
import { HexToRGB, RGBToHex } from '../../library/hex-rgb.library';

@Component({
    selector: 'rgb-create-dynamic-cell',
    template: `
        <rgb-dynamic-cell *ngIf="item.Id"
            [item]="item"
            [itemProp]="itemProp"
            [service]="service"
            [requiredEditClaimType]="requiredEditClaimType"
            [useUpdateWithFks]="useUpdateWithFks"
        ></rgb-dynamic-cell>

        <ng-container *ngIf="!item.Id">
            <app-rgb [rgb]="rgb" (change)="rgbChange()"></app-rgb>
        </ng-container>
    `,
})
export class RGBInlineCreateDynamicCell<T extends IEntity> extends InlineCreateDynamicCellBase<T> {
    rgb = { red: null, green: null, blue: null };
    itemPropValueDiff: string;

    rgbChange(): void {
        (<any> this.item).HexCode = RGBToHex(this.rgb);
    }

    ngDoCheck(): void {
        if (this.item && !this.item.Id && (<any> this.item).HexCode && (<any> this.item).HexCode !== this.itemPropValueDiff) {
            let hexCode: string = (<any> this.item).HexCode;
            if (hexCode[0] === '#') {
                hexCode = hexCode.substring(1);
            }
            this.itemPropValueDiff = hexCode;
            this.rgb = HexToRGB(hexCode);
        }
    }

}
